import React, { Component } from 'react'
import { connect } from "react-redux"

import { Card, CardBody, Col, Row } from 'reactstrap';

import { submitApiCallAction } from "store/actions";
import { CART_ORDER_CONFIRMATION, USER_ACCOUNT_EDIT } from 'routes/paths';

import { ThemeButton, ThemeButtonLink } from 'theme/elements/buttons';

import { addItemToCart } from "store/actions"

import SendCheckrInvitationForm from 'components/forms/formik/background-checks/SendCheckrInvitationForm';
import { Link } from 'react-router-dom';

class BackgroundCheck extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showPaymentModal: false,
            showWorkLocation: false,
            showDataSafe: false,
        }
        this.onToggleModal.bind(this)
        this.addBackgroundCheckToCart.bind(this)
    }

    onToggleModal = () => {
        this.setState(prevState => ({
          showPaymentModal: !prevState.showPaymentModal,
        }))
    }
    
    addBackgroundCheckToCart = () => {
        let { tutorApplicationReducer: {tutorApplication}, cart, onAddItemToCart, user } = this.props;

        let cartItems = cart.items;
        let backgroundCheckPresent = false;

        cartItems.forEach(item => {
            if(item.id == tutorApplication.id && item.type == "backgroundCheck"){
                backgroundCheckPresent = true;
            }
        });

        if(!backgroundCheckPresent){
            let cartItem = {
                id: tutorApplication.id,
                type: "backgroundCheck",
                object: "backgroundCheck",
                objectId: tutorApplication.id,
                name: "Background Check For " + user.firstName + " " + user.lastName,
                price: tutorApplication.backgroundCheckFee,
                quantity: 1,
                currency: tutorApplication.backgroundCheckCurrency || "usd",
                metadata: {
                    userRefId: user.id,
                }
              };
              onAddItemToCart(cartItem);
        }
        
    }

    render() {
    
        let { tutorApplicationReducer: {tutorApplication}, user, apiResponse } = this.props;
        let { showWorkLocation, showDataSafe } = this.state;
        let backgroundCheckFee = tutorApplication.backgroundCheckFee ? tutorApplication.backgroundCheckFee : 0;
        let offered = tutorApplication.offered ? tutorApplication.offered : false;
        
        return (
            <>
                <Row>
                    <Col md={{size: 10, offset: 1}}> 
                        <h2>Instructions</h2>
                        <p style={{fontSize: "1rem"}}>This is a 2-Step Process: <br />
                            1) First submit payment for the background check.<br />
                            2) Then <b>return to this page</b> and initiate the background test using the form below.
                        </p>
                        <p style={{fontSize: "1rem", color: "darkblue"}}>
                            Background Checks are performed by Checkr, Inc. Passing a background check is <b>mandatory</b>. 
                        </p>
                        <h4>Status: {(tutorApplication.backgroundStatus || (tutorApplication.backgroundPaid ? "paid" : "-")).toUpperCase()}</h4>  
                        <h4>Result: {(tutorApplication.backgroundResult || "-").toUpperCase()}</h4>  
                        
                        <br />

                        {
                            (tutorApplication.backgroundStatus == null || tutorApplication.backgroundStatus == "") &&
                            <>
                                
                                
                                <p>
                                    <span style={{color: "darkred", fontWeight: "bold", fontSize: "1rem"}}>If you have the results of a background check performed within the last year by Checkr, please email us and include a copy of the results.</span> 
                                </p>
                                <br />
                                
                                    {
                                        backgroundCheckFee > 0 && !tutorApplication.backgroundPaid &&
                                        <>
                                            <h4 style={{fontWeight: "bold"}}><u>Submit Payment</u></h4>
                                            <p style={{fontSize: "1rem"}}>
                                                There is a ${backgroundCheckFee} (USD) fee to process the background check. The fee is <b>non-refundable</b> and the fee is subject to change without notice.
                                            </p>
                                                <br />
                                                {
                                                    offered ?
                                                            <ThemeButtonLink to={CART_ORDER_CONFIRMATION}
                                                                onClick={this.addBackgroundCheckToCart}
                                                                color="info"
                                                            >
                                                                Pay Background Check Fee
                                                            </ThemeButtonLink>
                                                        :
                                                            <ThemeButton color="info" disabled={true}>
                                                                Pay Background Check Fee
                                                            </ThemeButton>
                                                }
                                                <br />
                                                <br /> 
                                        </>
                                    }
                                
                                <h4 style={{fontWeight: "bold"}}><u>Initiate Background Check</u></h4>
                                <p style={{fontSize: "1rem"}}>
                                    Select your work location and submit the form below. Checkr will send you an invitation via email with a link to their website where you will provide your information (e.g. DOB, SSN). <br />
                                    <span style={{fontWeight: "bold"}}><em>Be sure to check both the Inbox and Spam/Junk folders of your email account.</em></span>
                                    <br /><br />
                                    If the name (<b>{`${user.firstName} ${user.lastName}`}</b>) or email address (<b>{user.email}</b>) on your account is inaccurate, please <Link to={USER_ACCOUNT_EDIT + "/" + user.id} className="text-primary">edit your information</Link> BEFORE submitting this form.
                                </p>
                                    <Row>
                                        <Col md={{size: 10}} lg={{size: 8}} xl={{size: 6}}>
                                            <Card style={{border: "1px gray solid"}}>
                                                <CardBody>  
                                                    <label>Select Your Work Location</label> 
                                                    <SendCheckrInvitationForm key={tutorApplication.backgroundCheckId} userId={user.id} backgroundCheckId={tutorApplication.backgroundCheckId} tutorApplicationId={tutorApplication.id} 
                                                        disabled={apiResponse.loading || (backgroundCheckFee > 0 ? (tutorApplication.backgroundPaid ? false : true) : false )}/>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                        
                                    <br />
                                <p style={{fontSize: "1rem"}}>
                                    <a className='text-primary' onClick={() => this.setState({showWorkLocation: !showWorkLocation})}>What Is My Work Location?</a>
                                    {
                                        showWorkLocation && 
                                        <>
                                            <br />
                                            <b>USA-based Applicants:</b> The work location is the country-state pairing where you will be performing your work. This is likely where you live, even for remote tutors. For example, if you live in Columbus, Ohio and work with a student in Miami, FL online, your work location is Columbus, OH. <br /><br />
                                            <b>Non USA-based applicants:</b> Select the country where you will be performing your work. This is likely where you live, even for remote tutors.<br />
                                            <br />
                                            Please contact us if you are unsure which location to select.  For example, if you have a USA citizen/resident who recently moved to France, we will need to determine which background check would be most relevant to perform.
                                        </>
                                    }
                                        
                                    {/* 3) Return to this page and follow the new link below to Checkr's Background Check Form. You will provide your information (e.g. SSN) on Checkr's website to initiate the background check.
                                    <br /><br />
                                    The link below will be active <b>AFTER</b> you receive an offer. We neither receive nor store any of your private information. */}
                                </p> 
                            </>
                        }
                        <p style={{fontSize: "1rem"}}>
                            <a className='text-primary' onClick={() => this.setState({showDataSafe: !showDataSafe})}>Is My Data Safe?</a>      
                            {
                                showDataSafe &&
                                    <><br />BuffTutor does not receive, handle or store any of your private, personal information.</>
                            }
                        </p>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer, tutorApplicationReducer, cartReducer, userReducer }) => ({
    apiResponse: apiResponseReducer,
    tutorApplicationReducer,
    cart: cartReducer,
    user: userReducer
  })
  
const mapDispatchToProps = dispatch => ({
    onAddItemToCart: cartItem => dispatch(addItemToCart(cartItem)),
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(BackgroundCheck)