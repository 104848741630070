import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { getCurrencySymbol } from 'scripts/general'

import BTNMetaTags from "components/metatags/BTNMetaTags"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { ThemeSoftBadge } from 'theme/elements/badges'

export default class TutorRequestDetails extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         showMore: false
      }
    }

    render() {
        let { tutorRequest={} } = this.props;
        let { showMore } = this.state;

        let currencySymbol = getCurrencySymbol(tutorRequest.currency || "usd");

        return (
            <>
                <BTNMetaTags title="Tutor Request Details" />
                
                <Card>
                    <CardBody>
                        <CardTitle className="mb-2 h4">Tutor Request Details</CardTitle>               
                        <h5>
                            {tutorRequest.course}
                            {
                                tutorRequest.school !== null &&
                                <>
                                {" "}@ {tutorRequest.school}
                                </>
                            }
                        </h5>
                        <Row>
                            <Col sm="12">
                                {
                                    tutorRequest.inPerson == true &&
                                        <ThemeSoftBadge color="info">In-Person</ThemeSoftBadge>
                                }
                                {" "}
                                {
                                    tutorRequest.online == true &&
                                        <ThemeSoftBadge color="success">Online</ThemeSoftBadge>
                                }
                                {" "}
                                {
                                    tutorRequest.scholarship == true &&
                                        <ThemeSoftBadge color="warning">Scholarship</ThemeSoftBadge>
                                }
                                <br />
                                <p className="text-muted">
                                    <b>ID: </b> {tutorRequest.id} <br />
                                    <b>Budget: </b> 
                                        <span className="text-success me-2">    
                                            {currencySymbol}
                                            {
                                                tutorRequest.minRate > 0 && 
                                                <>
                                                    {tutorRequest.minRate} - 
                                                </>
                                            }
                                            {tutorRequest.maxRate} /hr
                                        </span> <br /><br />
                                    <a className="text-primary" onClick={() => {this.setState({showMore: !showMore})}}>{showMore ? "Hide Details" : "More Details"}</a>
                                    <br /><br />
                                    {
                                        showMore &&
                                            <>
                                                <b>Frequency:</b> {tutorRequest.frequency} <br />
                                                <b>Times: </b> {tutorRequest.sessionTime} <br />
                                                
                                                
                                                {
                                                    tutorRequest.concerns != null && tutorRequest.concerns != "" &&
                                                        <>
                                                            <b>Additional: </b>{tutorRequest.concerns}
                                                            <br />
                                                        </>
                                                    
                                                }
                                                <b>Dates: </b> {moment(tutorRequest.startDate).format("MMM D")} to {moment(tutorRequest.endDate).format("MMM D YYYY")}
                                                <br />
                                                <small>Start & end dates are estimates</small>
                                            </>
                                    }
                                </p>
                                {/* <p>
                                    <a href="#progress-timeline">View Progress</a> <br />
                                </p> */}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>  
            </>
        )
    }
}

TutorRequestDetails.protoType = {
    tutorRequest: PropTypes.object.isRequired,
}