import React, { Component } from 'react'
import { ThemeButtonLink } from 'theme/elements/buttons';

import { PROFICIENCY_EXAM_LIST } from 'routes/paths';
import { Col, Row } from 'reactstrap';

export default class ProficiencyExamsTab extends Component {    
    render() {
        return (
            <>
                <Row>
                    <Col md={{size: 10, offset: 1}}>
                        <p>Pass proficiency exams for courses you wish to tutor. If the courses you wish to tutor do not have a proficiency exam, please skip this step.</p>
                    
                        <p>
                            <b>SUGGESTIONS:</b> <br />
                            Start with the highest level course for each subject you wish to tutor.  <br />
                            If you tutor a higher level Math course (Calc and above), skip the lower level courses (Algebra, Trig, Geometry, Precalc) 
                        </p>
                        <p><b>Applications missing relevant proficiency exam results will be considered incomplete and will not be reviewed. </b></p>

                        <ThemeButtonLink to={PROFICIENCY_EXAM_LIST}>
                            Take Exams
                        </ThemeButtonLink>
                    </Col>
                </Row>
            </>
        )
    }
}
