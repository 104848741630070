//EXTERNAL API urls (SPRING BOOT)

//ACCOUNT
const account_prefix = "/account";
export const GET_ACCOUNT_USER_DETAILS = account_prefix + "/user"; // + userId
export const GET_ACCOUNT_STUDENT_REQUESTS_SUMMARY = account_prefix + "/students/tutor-requests-summary"; //+studentId
export const GET_ACCOUNT_STUDENT_RECENT_SESSIONS = account_prefix + "/students/recent-sessions"; //+studentId
export const GET_ACCOUNT_TUTOR_RECENT_SESSIONS = account_prefix + "/tutors/recent-sessions"; //+tutorId
export const GET_ASSIGNMENTS_FOR_STUDENT = account_prefix + "/students/get-assignments"; // + studentId
export const GET_PREVIOUS_ASSIGNMENTS_FOR_STUDENT = account_prefix + "/students/previous-assignments"; // + studentId
export const GET_ASSIGNMENTS_FOR_TUTOR = account_prefix + "/tutors/get-assignments"; // + tutorId

export const GET_PAYMENTS_FOR_STUDENT = account_prefix + "/students/get-payments"; // + userId
export const GET_TRANSFERS_FOR_TUTOR = account_prefix + "/tutors/get-transfers"; // + userId


//ASSIGNMENTS
const assign_prefix = "/assignments";
export const GET_ALL_ASSIGNMENTS = assign_prefix+ "/getActive"; //CHECK WITH NEW API SYSTEM
export const POST_ADD_RETURNING_STUDENT = assign_prefix + "/add-returning-student";
export const PATCH_UPDATE_END_DATE = assign_prefix + "/update-end-date";

//BACKGROUND CHECKS
const back_prefix = "/background-checks";
export const POST_CREATE_BACKGROUND_CHECK = back_prefix + "/create"; //+userID
export const PATCH_UPDATE_BACKGROUND_CHECK = back_prefix + "/update"; //+backID
export const POST_CREATE_CHECKR_INVITATION_BASIC = back_prefix + "/checkr-invitation";

//CONTACT INFO
const contact_prefix = "/contact-info";
export const GET_USER_CONTACT_INFO = contact_prefix; // + userId
export const PATCH_USER_CONTACT_INFO = contact_prefix; // + userId
export const PATCH_USER_PASSWORD = contact_prefix + "/update-password"; // + userId
export const PATCH_USER_POSTAL_CODE = contact_prefix + "/update-postal-code"; // + userId

//EVALUATIONS
const eval_prefix = "/evaluations";
export const POST_TUTOR_EVALUATION = eval_prefix + "/students/submit-evaluation";
export const GET_TUTOR_EVALUATIONS = eval_prefix + "/tutors"; // +tutorId
export const GET_ASSIGNMENT_TO_EVALUATE = eval_prefix + "/students/assignment";
export const POST_SUBMIT_TUTOR_EVALUATION_DETAILED = eval_prefix + "/students/submit-detailed-evaluation";


//TUTOR SESSIONS
const sess_prefix = "/tutor-sessions";
export const GET_ACTIVE_TUTOR_SESSIONS_FOR_STUDENT = sess_prefix + "/students/get-active-sessions"; //+ studentId
export const POST_DELETE_TUTOR_SESSION_REQUEST = sess_prefix +  "/students/charge-session/delete"; //+ sessionId

export const GET_ACTIVE_TUTOR_SESSIONS_FOR_TUTOR = sess_prefix + "/tutors/get-active-sessions"; //+ tutorId
export const POST_CHARGE_TUTOR_SESSION = sess_prefix + "/tutors/charge-session"; //+ assignID
export const POST_DELETE_TUTOR_SESSION = sess_prefix + "/tutors/charge-session/delete"; //+ sessionID
export const POST_CANCEL_TUTOR_SESSION_SCHEDULED_CHARGE = sess_prefix + "/tutors/charge-session/cancel"; //+sessionId

//TUTOR REQUESTS
const req_prefix = "/tutor-requests";
export const GET_TUTOR_REQUEST_PROGRESS_FOR_STUDENT = req_prefix + "/students/get-progress";
export const  GET_CURRENT_TUTOR_REQUESTS_FOR_STUDENT = req_prefix + "/students/get-current-requests";
export const  PATCH_DELETE_TUTOR_REQUEST = req_prefix + "/delete"; //+refid


//POTENTIAL TUTORS
const potential_prefix = "/potential-tutors";
export const POST_CREATE_POTENTIAL_TUTORS = potential_prefix +"/create-potential-tutors"; //admin
export const PATCH_SUBMIT_REVIEW_STUDENT_DECISION = potential_prefix + "/submit-student-decision" //+ potential tutor id
export const PATCH_SELECTED_POTENTIAL_TUTORS_DECLINED = potential_prefix + "/selected-potential-tutors-declined"; //+ tutor request id

//PROFICIENCY EXAMS
const prof_prefix = "/proficiency-exams";
export const GET_LOAD_TUTOR_PROFICIENCY_EXAMS = prof_prefix + "/load-proficiency-exams";

//ASSESSMENTS
const assess_prefix = "/assessments";
export const GET_USER_ASSESSMENTS = assess_prefix + "/user-assessments"; //+userId
export const GET_USER_ASSESSMENT_GRADES = assess_prefix + "/user-assessments/grades"; // + userId
export const POST_CREATE_ASSESSMENT = assess_prefix + "/create-assessments";
export const GET_LOAD_ASSESSMENT = assess_prefix + "/get-assessment"; // + assessId
export const PATCH_SUBMIT_ASSESSMENT = assess_prefix + "/submit-assessment";
export const GET_LOAD_ASSESSMENT_SOLUTIONS = assess_prefix + "/get-assessment/solutions"; // + assessId


//TUTOR CONTRACTS
const contracts_prefix = "/tutor-contracts";
export const PATCH_SUBMIT_TUTOR_CONTRACT_STUDENT_DECISION = contracts_prefix + "/students/submit-student-decision" // + tutorContractId
export const GET_TUTOR_CONTRACT_FOR_STUDENT = contracts_prefix + "/students/get-tutor-contract";
export const GET_TUTOR_CONTRACTS_FOR_TUTOR = contracts_prefix + "/tutors/get-tutor-contracts";
export const PATCH_SUBMIT_TUTOR_CONTRACT_TUTOR_DECISION = contracts_prefix + "/tutors/submit-tutor-decision";
export const POST_CREATE_TUTOR_CONTRACTS = contracts_prefix + "/admin/create-tutor-contracts"; //admin

//APP
export const GET_APP_SETTING = "/settings/get-setting"; // + setting

//CALENDAR
const calendar_prefix = "/calendar";
export const POST_CREATE_TUTOR_CALENDAR = calendar_prefix + "/create-tutor-calendar";
export const POST_CREATE_TUTOR_AVAILABILITY_EVENT = calendar_prefix + "/create-tutor-availability-event";
export const POST_CREATE_TUTOR_SESSION_EVENT = calendar_prefix + "/create-tutor-session-event";
export const PUT_UPDATE_TUTOR_AVAILABILITY_EVENT = calendar_prefix + "/update-tutor-availability-event";
export const DELETE_TUTOR_AVAILABILITY_EVENT = calendar_prefix + "/delete-tutor-availability-event";
//CART
const cart_prefix = "/cart";
export const GET_PAYMENT_METHODS  = cart_prefix + "/get-payment-methods";
export const POST_CALCULATE_CART_TOTAL = cart_prefix + "/calculate-cart-total"

//PAYMENT SETTINGS
const payment_path = "/payment-settings";
export const GET_USER_PAYMENT_METHODS = payment_path; // + userId
export const PATCH_MAKE_CARD_DEFAULT_PAYMENT_METHOD = payment_path + "/cards/make-default"; // + cardId

//PROFILE
const profile_prefix = "/tutor-profiles";
export const GET_TUTOR_PROFILE = "/tutor-profiles" //+ userId
export const PATCH_UPDATE_PROFILE_STATUS = profile_prefix + "/update-status" // + userId
export const GET_TUTOR_PROFILE_DETAILS = profile_prefix + "/details"; // +userId
export const PATCH_TUTOR_PROFILE_DETAILS = profile_prefix + "/details/update"; //+userId
export const POST_UPDATE_AVATAR = profile_prefix + "/avatar/update";
export const PATCH_UPDATE_PROFILE_SUBJECTS = profile_prefix + "/update-subjects";; //+userId
export const PATCH_UPDATE_PROFILE_SESSION_LOCATIONS = profile_prefix + "/update-session-locations";; //+userId
export const PATCH_UPDATE_PROFILE_GEOLOCATIONS = profile_prefix + "/update-geolocations";; //+userId

//REFUNDS
const refund_prefix = "refunds"
export const POST_CREATE_BALANCE_REFUND_REQUEST = refund_prefix + "/request-balance-refund"; // + tutorContractRefId

//SEARCH
const search_prefix = "/search";
const search_tutors_prefix = "/search-tutors";
export const POST_SEARCH_TUTORS = search_tutors_prefix + "/public"
export const POST_SEARCH_TUTORS_ADMIN = search_tutors_prefix + "/admin"
export const POST_SEARCH_FIND_PREVIOUS_STUDENT = search_prefix + "/find-previous-student"

//SUBJECTS
const subjectPath = "/subjects";
export const GET_SUBJECTS = subjectPath + "/list-subjects";

//LOGIN
export const POST_BTN_LOGIN = "/authenticate"
export const POST_BTN_PASSWORD_UPDATE = "/authenticate/password"
export const POST_BTN_PASSWORD_EMAIL_RESET = "/authenticate/password/email"
//export const POST_BTN_LOGOUT = "/authenticate/logout"


//USER
export const GET_BTN_USER = "/users/get"
export const POST_UPDATE_BTN_USER = "/users/update"


//TUTOR REQUESTS
export const POST_TUTOR_REQUEST = "/request-a-tutor"; // public


//TUTOR APPLICANTS
const applicants_prefix = "/tutor-applicants";
export const GET_TUTOR_APPLICATION = applicants_prefix + "/get-tutor-application"; //+userRefID
export const POST_UPLOAD_TRANSCRIPTS = applicants_prefix + "/upload-transcripts";
export const SUBMIT_TUTOR_APPLICATION = applicants_prefix + "/submit-tutor-application";
export const GET_TUTOR_CONTRACTOR_AGREEMENT = applicants_prefix + "/get-contractor-agreement";
export const POST_SIGN_CONTRACTOR_AGREEMENT = applicants_prefix + "/sign-contractor-agreement";

/** 
 * STRIPE
*/
export const POST_CREATE_STRIPE_SETUP_INTENT = "/stripe/create-setup-intent"
// export const POST_CREATE_STRIPE_PAYMENT_INTENT = "/stripe/create-payment-intent"
export const POST_CREATE_STRIPE_PAYMENT_INTENT_ON_CART = "/stripe/create-cart-payment-intent"

//CONNECT
export const connect_prefix = "/stripe-connect"
export const GET_STRIPE_CONNECT_ACCOUNT = connect_prefix + "/get-account"
export const POST_CREATE_STRIPE_CONNECT_ACCOUNT = connect_prefix + "/create-account"
export const POST_STRIPE_CREATE_ONBOARDING_LINK = connect_prefix + "/create-onboarding-link"
export const POST_STRIPE_CREATE_LOGIN_LINK = connect_prefix + "/create-login-link"

export const POST_DELETE_STRIPE_STANDARD_ACCOUNT = connect_prefix + "/delete-stripe-standard-account"

//CHAT
const chat_prefix = "/chats"
export const GET_USER_CHATS = chat_prefix + "/get-user-chats" // + userId
export const GET_CHAT_MESSAGES = chat_prefix + "/get-chat-messages" // + chatId
export const GET_CHAT_THREAD = chat_prefix + "/get-chat-thread" // + chatId
export const POST_SUBMIT_CHAT_MESSAGE = chat_prefix + "/submit-chat-message" // + chatId
export const POST_UPLOAD_CHAT_FILE = chat_prefix + "/upload-chat-file" // + chatId
export const POST_UPLOAD_CHAT_IMAGE = chat_prefix + "/upload-chat-image" // + chatId
export const POST_LOAD_CHAT_DETAILS = chat_prefix + "/load-chat-details" // + chatId
export const PATCH_BLOCK_CHAT_PARTICIPANT = chat_prefix + "/block-chat-participant" // + threadId/participantId
export const PATCH_UNBLOCK_CHAT_PARTICIPANT = chat_prefix + "/unblock-chat-participant" // + threadId/participantId

export const GET_ADMIN_RECENT_CHATS = chat_prefix + "/admin/get-recent-chats"
export const GET_ADMIN_FLAGGED_CHATS = chat_prefix + "/admin/get-flagged-chats";
export const GET_ADMIN_USER_FLAGGED_CHATS = chat_prefix + "/admin/get-user-flagged-chats";

//ADS
const ads_prefix = "ads";
export const GET_LEAD_BIDS_FOR_TUTOR_REQUEST = ads_prefix + "/get-tutor-request-leads";
export const GET_LEAD_BID_DETAILS = ads_prefix + "/get-lead-details";

//LEADS
const lead_prefix = "leads"
export const GET_ACTIVE_LEADS_FOR_TUTORS = lead_prefix + "/get-active-leads";
export const POST_ADMIN_CREATE_LEAD = lead_prefix + "/create-lead"; 
export const POST_CREATE_LEAD_BID = lead_prefix + "/create-lead-bid";
export const PATCH_DELETE_LEAD_BID = lead_prefix + "/delete-lead-bid";		
export const GET_ADMIN_ALL_LEADS = lead_prefix + "/admin/get-all-leads";
export const GET_ADMIN_SUBMITTED_LEAD_BIDS = lead_prefix + "/admin/get-submitted-lead-bids";
export const PATCH_ADMIN_GENERATE_BIDS_FOR_LEAD = lead_prefix + "/admin/create-ad-bids";

//AD ACCOUNTS
const ads_account_prefix = "ads/account"
export const GET_AD_ACCOUNT_FOR_OWNER = ads_account_prefix + "/get-account";
export const POST_CREATE_ACCOUNT_FROM_APPLICANT = ads_account_prefix + "/create-account-from-applicant";
export const POST_CREATE_ACCOUNT_FROM_PROFILE = ads_account_prefix + "/create-account-from-profile";
export const PATCH_UPDATE_AD_ACCOUNT_AVATAR = ads_account_prefix + "/update-account-avatar";
export const PATCH_UPDATE_AD_ACCOUNT_SUBJECTS = ads_account_prefix + "/update-account-subjects";
export const PATCH_UPDATE_AD_ACCOUNT_LEAD_NOTIFICATION = ads_account_prefix + "/update-lead-notification";

//MARKETPLACE
const market_prefix = "market";
export const GET_MARKETPLACE = market_prefix + "/marketplace";
export const GET_MARKETPLACE_ADMIN = market_prefix + "/marketplace/admin";

// export const GET_MARKET_REQUEST_PROGRESS = market_prefix + "/request-progress";
export const GET_MARKET_REQUEST = market_prefix + "/get-market-request";
export const GET_MARKET_TUTOR_REQUEST_WITH_PROPOSALS =  market_prefix + "/get-tutor-request";
export const GET_PENDING_PROPOSALS_FOR_TUTOR = market_prefix + "/get-pending-proposals";
export const POST_CREATE_MARKET_REQUEST_FROM_TUTOR_REQUEST = market_prefix + "/create-request";
export const POST_CREATE_MARKET_PROPOSAL = market_prefix + "/create-proposal";
export const PATCH_UPDATE_PROPOSAL_RATE = market_prefix + "/update-proposal-rate";
export const PATCH_REJECT_PROPOSAL = market_prefix + "/reject-proposal";
export const PATCH_EXTEND_PROPOSAL_DEADLINE = market_prefix + "/extend-proposal-deadline";
export const PATCH_TUTOR_REACTIVATE_PROPOSAL = market_prefix + "/reactivate-proposal";
export const POST_STUDENT_ACCEPT_PROPOSAL = market_prefix + "/accept-proposal";

export const POST_DELETE_MARKET_REQUEST = market_prefix + "/admin/delete-market-request";
export const POST_DELETE_MARKET_PROPOSAL = market_prefix + "/admin/delete-market-proposal";
export const PATCH_DISABLE_MARKET_PROPOSAL = market_prefix + "/admin/disable-market-proposal";
export const PATCH_ENABLE_MARKET_PROPOSAL = market_prefix + "/admin/enable-market-proposal";
export const POST_GOOGLE_SEARCH_COURSE = market_prefix + "/admin/google-search-course";

/**
 * Admin
 */

const admin = "admin";
const adminUser = admin + "/users";
export const GET_ADMIN_RECENT_USERS = adminUser + "/get-recent-users";
export const GET_ADMIN_DELETED_USERS = adminUser + "/get-deleted-users";
export const POST_LOAD_ACTIVE_TUTORS = adminUser + "/get-active-tutors";
export const POST_ADMIN_SEARCH_USERS = adminUser + "/search-users";
export const GET_ADMIN_USER_DETAILS = adminUser + "/details"; //+ userId
export const PATCH_ADMIN_USER_ADD_ROLE = adminUser + "/add-role";
export const PATCH_ADMIN_USER_REMOVE_ROLE = adminUser + "/remove-role";
export const POST_ADMIN_CLONE_USER = adminUser + "/clone-user";

export const GET_ADMIN_ACTIVE_ASSIGNMENTS = admin + "/assignments/active"
export const PATCH_ADMIN_DELETE_ASSIGNMENT = admin + "/assignments/delete-assignment" //+id

export const GET_ADMIN_PENDING_APPLICATIONS = admin + "/tutor-applications/pending"
export const GET_ADMIN_TUTOR_APPLICATION = admin + "/tutor-applications/applicant" //+appId

export const PATCH_ADMIN_DELETE_TUTOR_APPLICATION = admin + "/tutor-applications/applicant/delete" //+appId
export const PATCH_ADMIN_TUTOR_APPLICATION_OFFER_DECISION = admin + "/tutor-applications/applicant/offer-position" //+appId
export const PATCH_ADMIN_TUTOR_APPLICATION_HIRE_APPLICANT = admin + "/tutor-applications/applicant/hire-applicant" //+appId
export const POST_ADMIN_TUTOR_APPLICATION_EMAIL_REMINDER = admin + "/tutor-applications/applicant/exam-reminder" //+appId
export const POST_ADMIN_CREATE_FREE_BACKGROUND_CHECK_FOR_APPLICANT = admin + "/tutor-applications/applicant/free-background-check"; //+userId

// export const GET_ADMIN_UNADDRESSED_TUTOR_REQUESTS = admin + "/tutor-requests/get-unaddressed-requests"; 
export const GET_TUTOR_REQUEST = admin + "/tutor-requests"; //+trId
export const GET_ADMIN_SEMESTER_TUTOR_REQUESTS_WITHOUT_ASSIGNMENTS = admin + "/tutor-requests/get-semester-requests-without-assignments"; 
export const PATCH_ADMIN_UPDATE_TUTOR_REQUEST = admin + "/tutor-requests/update-tutor-request"; 
export const PATCH_UPDATE_TUTOR_REQUEST_SUBJECTS = admin + "/tutor-requests/update-subjects"; //+id
export const PATCH_ADMIN_NO_TUTOR_AVAILABLE = admin + "/tutor-requests/no-tutor-available"; 
export const POST_ADMIN_DUPLICATE_TUTOR_REQUEST = admin + "/tutor-requests/duplicate-tutor-request";
export const POST_ADMIN_TUTOR_REQUEST_SEND_SMS_REMINDER = admin + "/tutor-requests/send-sms-reminder"; //+tutorRequestId
export const POST_ADMIN_TUTOR_REQUEST_SEND_EMAIL_REMINDER = admin + "/tutor-requests/send-email-reminder"; //+tutorRequestId

export const POST_ADMIN_ISSUE_PAYMENT_REFUND = admin + "/refunds/issue-payment-refund";
export const POST_ADMIN_CREATE_BALANCE_REFUND = admin + "/refunds/create-balance-refund";
export const POST_ADMIN_ISSUE_BALANCE_REFUND = admin + "/refunds/issue-balance-refund";

export const GET_ADMIN_ANALYTICS_DATA = admin + "/analytics"; 

//METRICS
const metricsPath = admin + "/metrics";
export const POST_RETRIEVE_TUTOR_CONVERSION_RATES = metricsPath + "/tutor-conversion-rates";
export const POST_RETRIEVE_THREAD_CONVERSION_RATES = metricsPath + "/thread-conversion-rates";
export const POST_RETRIEVE_BOUNCE_RATES = metricsPath + "/bounce-rates";
export const POST_RETRIEVE_TUTOR_ASSIGNMENTS_METRICS = metricsPath + "/tutor-assignments";
export const POST_RETRIEVE_STUDENT_RETURN_RATES = metricsPath + "/student_return_rates";
export const POST_RETRIEVE_RECENT_TUTOR_ACTIVITIES = metricsPath + "/recent-tutor-activities";

// export const GET_ADMIN_SCHOLARSHIP_ASSIGNMENT = admin + "/scholarships/scholarship-assignment";
export const PATCH_ADMIN_SET_SCHOLARSHIP_ASSIGNMENT_HOURS = admin + "/scholarships/set-hours";
export const GET_ADMIN_ACTIVE_SCHOLARSHIP_ASSIGNMENTS = admin + "/scholarships/active-scholarship-assignments";
export const PATCH_ADMIN_ACTIVE_SCHOLARSHIP_CHARGES_MARK_AS_PAID = admin + "/scholarships/mark-as-paid";

const googleAPIPath = admin + "/google";
export const POST_GENERATE_AUTH_URL = googleAPIPath + "/auth-url";
export const POST_GOOGLE_AUTH_CALLBACK = googleAPIPath + "/auth-callback";

export const POST_GOOGLE_API_SUBMIT_URLS = admin + "/google/submit-batch-urls"; //+ submit vs delete
export const POST_CREATE_AND_INDEX_URLS = googleAPIPath + "/create-index-urls";

export const POST_GOOGLE_GET_ACCOUNT_LIST = googleAPIPath + "/accounts";
export const POST_GOOGLE_GET_LOCATIONS_LIST = googleAPIPath + "/locations";
export const POST_GOOGLE_POSTS_UPLOAD_IMAGE = googleAPIPath + "/posts/upload-image";
export const POST_GOOGLE_POSTS_CREATE = googleAPIPath + "/posts/create";
export const POST_GOOGLE_CREATE_BUSINESS_SERVICES = googleAPIPath + "/services/create";
export const POST_GOOGLE_CREATE_BUSINESS_SERVICE_AREA = googleAPIPath + "/service-area/create";
export const POST_GOOGLE_CREATE_DEFAULT_BUSINESS_SERVICE_AREA = googleAPIPath + "/service-area/default/create";
export const POST_UPLOAD_BUSINESS_MEDIA = googleAPIPath + "/media/upload";
export const PATCH_SAVE_GOOGLE_BUSINESS_DETAILS = googleAPIPath + "/save-business-details";
export const PATCH_DISABLE_GOOGLE_BUSINESS = googleAPIPath + "/disable-google-business";
export const POST_FETCH_BUSINESS_VERIFICATION_OPTIONS = googleAPIPath + "/verification/fetch-options";
export const POST_REQUEST_BUSINESS_VERIFICATION = googleAPIPath + "/verification/request";
export const POST_COMPLETE_BUSINESS_VERIFICATIONS = googleAPIPath + "/verification/complete";


// export const POST_SEARCH_CONSOLE = googleAPIPath + "/search";
export const POST_GOOGLE_SUBMIT_SITEMAPS = googleAPIPath + "/search/submit-all-sitemaps";

export const GET_GMAIL_INBOX = googleAPIPath + "/get-inbox";
export const GET_THREAD_MESSAGES = googleAPIPath + "/threads";
export const POST_SEND_GMAIL_EMAIL = googleAPIPath + "/send-email";

//SCHEDULED JOBS
const batchPrefix = "/batch";
export const GET_LIST_FAILED_JOBS = batchPrefix + "/failed-jobs";
export const POST_RESTART_CHARGE_SESSION_JOB = batchPrefix + "/failed-jobs/restart-charge-session-job";
export const POST_RESTART_ALL_CHARGE_SESSION_JOBS = batchPrefix + "/failed-jobs/restart-all-charge-session-jobs";
export const POST_RESTART_REQUEST_TUTOR_JOB = batchPrefix + "/failed-jobs/restart-request-tutor-job";
export const POST_RESTART_ALL_REQUEST_TUTOR_JOBS = batchPrefix + "/failed-jobs/restart-all-request-tutor-jobs";