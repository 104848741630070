import FileUpload from 'components/fileUpload/FileUpload'
import React, { Component } from 'react'
import { connect } from "react-redux"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { Col, Row } from 'reactstrap'
import { ThemeSpinner } from 'theme/elements/spinner'


class UploadApplicantFiles extends Component {
    constructor(props) {
        super(props)
        this.state = {
          
        }
       
        this.onUploadTranscripts.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    onUploadTranscripts = (selectedFiles) => {
        const { onSubmitApiCall, tutorApplicationReducer } = this.props;
        
        const tutorApplication = tutorApplicationReducer.tutorApplication;
        let data = {tutorApplicationId: tutorApplication.id, files: selectedFiles};

        onSubmitApiCall("uploadTranscripts", data);
    }

    render() {
        const { apiResponse, tutorApplicationReducer } = this.props;
        const loading = apiResponse.loading;
        const tutorApplication = tutorApplicationReducer.tutorApplication;
        let uploads = tutorApplication.uploads || [];

        return (
            <>
                <Row>
                    <Col md={{size: 10, offset: 1}}>
                        {
                            loading && 
                                <center><ThemeSpinner /></center>
                        }
                        <p>Please upload your most recent academic transcript (an unofficial one is sufficient). Also include all transcripts that show your performance in the courses you wish to tutor.</p> 
                        <p><b>Only upload PDF and image files.<br />
                        Do not upload high school transcripts.</b></p>
                        <p>If you cannot access your transcripts (for example, if you graduated a while ago), here are acceptable alternatives:</p>
                        <ul>
                            <li>Copy or scan of your degree(s)/diploma(s) </li>
                            <li>Active teaching license</li>
                        </ul>
                        {
                            uploads.length > 0 &&
                                <p className="text-success"><b>{uploads.length} File(s) Uploaded</b></p>
                        }
                        
                    </Col>
                </Row>
                
                <Row>
                    <Col md={{size: 10, offset: 1}}>
                        <FileUpload 
                            accept={".pdf, image/*"}
                            onSubmit={this.onUploadTranscripts}/>
                        {
                            loading && 
                                <center><ThemeSpinner /></center>
                        }
                    </Col>
                </Row>  
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer, tutorApplicationReducer }) => ({
    apiResponse: apiResponseReducer,
    tutorApplicationReducer
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(UploadApplicantFiles)