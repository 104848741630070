import React, { Component } from 'react'
import { connect } from "react-redux"
import { Link, Redirect } from 'react-router-dom';

import { Dropdown, DropdownButton } from "react-bootstrap";

import { Button, Card, CardBody, CardTitle, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'

import { createTimelineStatuses } from 'pages/students/tutor-match-process/scripts'
import TutorRequestDetails from 'pages/students/tutor-match-process/TutorRequestDetails'

import BTNMetaTags from "components/metatags/BTNMetaTags"
import RateSlider from 'components/slider/RateSlider';
import ThemeSkeleton from 'components/skeleton/ThemeSkeleton'
import VerticalTimeline from 'components/timeline/VerticalTimeline'

import { ThemeSpinner } from 'theme/elements/spinner'

import MarketProposalDetailsCard from './MarketProposalDetailsCard';
import MarketProposalSummaryCard from './MarketProposalSummaryCard';
import { proposalFilterOptions, proposalSortOptions } from './proposalFilterOptions'
import { STUDENT_MARKETPLACE_HOW_IT_WORKS, STUDENT_TUTOR_PROPOSAL_SCHOLARSHIP_CONFIRMATION, STUDENT_TUTOR_PROPOSAL_SELECT_PACKAGE } from 'routes/paths';

class MarketTutorRequestProgress extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            tutorRequestId: props.match.params.tutorRequestId,
            tutorRequest: {},
            selectedFilterOption: 1,
            filteredProposals: [],
            filterOnline: true,
            filterInPerson: true,
            filterOptions: proposalFilterOptions,
            
            removedProposalIds: [],

            filterMinRate: 10,
            filterMaxRate: 200,

            selectedSortOption: 1,
            sortOptions: proposalSortOptions,

            showProposal: false,
            selectedProposal: {},

            contractId: null,
            redirect: false
        }

        this.submitProposalDecision.bind(this);
    }
    
    componentDidMount() {
        let { tutorRequestId } = this.state;
        let { onSubmitApiCall } = this.props;
        onSubmitApiCall("getTutorRequestWithProposals", tutorRequestId);
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    onChangeRate = (render, handle, sliderValue) => {
        let filterMinRate = sliderValue[0];
        let filterMaxRate = sliderValue[1];
        this.setState({ filterMinRate, filterMaxRate });
    }

    onShowDetails = (proposalId = null) => {
        let { tutorRequest  } = this.state;
        let { proposals=[] } = tutorRequest;
        
        let selectedProposal = proposals.find(proposal => proposal.id ==proposalId);

        this.setState({selectedProposal, showDetails: true});
    }

    sortProposals = sortId => {
        // let { filteredProposals, sortOptions } = this.state;
        // let proposalsList = [...filteredProposals];

        // let selectedSortOption = sortOptions.find(option => option.id == sortId);
        
        // let sortedProposals = selectedSortOption.sort(proposalsList);

        // this.setState({
        //     selectedSortOption: sortId,
        //     filteredProposals: sortedProposals
        // });

    }

    filterInPersonProposals = proposals => {
        return proposals.filter(proposal => proposal.inPerson == true);
    }

    filterOnlineProposals = proposals => {
        return proposals.filter(proposal => proposal.online == true);
    }

    filterByRate = proposals => {
        let { filterMinRate, filterMaxRate } = this.state;

        return proposals.filter(proposal => proposal.rate >= filterMinRate && proposal.rate <= filterMaxRate)
    }

    filterProposals = eventKey => {
        let { tutorRequest, filterOptions } = this.state;
        let { proposals=[] } = tutorRequest;

        let proposalsList = [...proposals];
        let selectedFilterOption = filterOptions.find(option => option.id == eventKey)

        proposalsList = proposalsList.filter(proposal => selectedFilterOption.filter(proposal));

        this.setState({
            selectedFilterOption: selectedFilterOption.id,
            filteredProposals: proposalsList
        });
    }

    submitProposalDecision = async (formData) => {
        const { onSubmitApiCall } = this.props;
        let { tutorRequest, removedProposalIds } = this.state;
        let { decision, rejectReason, proposalId } = formData;
        
        //Check if contract exists
        let { proposals } = tutorRequest;
        let proposal = proposals.find(proposal => proposal.id == proposalId);

        // Submit Proposal Decision If Contract Does Not Exist
        if(decision){
            if(proposal && proposal.contractId){
                this.setState({contractId: proposal.contractId, redirect: true});
                return;
            }

            await onSubmitApiCall("acceptProposalCreateContract", proposalId);
            this.setState({redirect: true});
        }
        else{
            rejectReason = rejectReason != null && rejectReason.trim() != "" ? rejectReason.trim() : null;
            onSubmitApiCall("rejectProposal", {proposalId, rejectReason});

            removedProposalIds.push(proposal.id);
            this.setState({ removedProposalIds, showDetails: false });
        }
    }

    render() {
        let { tutorRequest, filteredProposals=[], filterOptions, selectedFilterOption,
                filterInPerson, filterOnline, sortOptions, selectedSortOption,
                removedProposalIds=[], 
                selectedProposal, showDetails,
                contractId, redirect } = this.state;
        let { apiResponse } = this.props;

        //First loading, load all proposals
        if(selectedFilterOption == 1 && filteredProposals.length == 0){
            filteredProposals = tutorRequest.proposals || [];
        }

        let loading = apiResponse.loading;
        let timelineStatuses = createTimelineStatuses(tutorRequest);

        let selectedTitle = filterOptions.find(opt => opt.id == selectedFilterOption).title;

        //Filter inPerson & online
        if( filterOnline == false || filterInPerson == false){
            if(filterOnline == false && filterInPerson == false){
                filteredProposals = [];
            }
            else{
                filteredProposals = filterInPerson ? this.filterInPersonProposals(filteredProposals) : this.filterOnlineProposals(filteredProposals);
            }
        }
        
        filteredProposals = filteredProposals.filter(proposal => !removedProposalIds.includes(proposal.id));
        filteredProposals = this.filterByRate(filteredProposals);
        
        //Sort Proposals
        let selectedSortOptionObject = sortOptions.find(option => option.id == selectedSortOption);
        let selectedSortTitle = selectedSortOptionObject.title;
        filteredProposals.sort(selectedSortOptionObject.sort);

        if(redirect && (selectedProposal.contractId != null || contractId != null)){
            let redirectURL = tutorRequest.scholarship ? STUDENT_TUTOR_PROPOSAL_SCHOLARSHIP_CONFIRMATION : STUDENT_TUTOR_PROPOSAL_SELECT_PACKAGE;
            redirectURL += "/" + contractId;
            return  <Redirect to={redirectURL} />
        } 

        return (
            <>
                <>
                    <BTNMetaTags title="Tutor Request Progress" />
                    
                    <Row>
                        <Col sm={4}>
                            {
                                tutorRequest.id == null
                                    ?   
                                        <Card>
                                            <CardBody>
                                                <CardTitle>Tutor Request Details</CardTitle>
                                                <br /><br />
                                                <center><ThemeSpinner /></center>
                                                <br /><br />
                                            </CardBody>
                                        </Card>
                                    :   
                                        <TutorRequestDetails key={"request-" + tutorRequest.id == null ? "" : tutorRequest.id} tutorRequest={tutorRequest}/>
                            }
                        </Col>
                        <Col sm={8}  id="progress-timeline">
                            <Card>
                                <CardBody>
                                    <CardTitle>Tutor Request Progress</CardTitle>
                                    <Link to={STUDENT_MARKETPLACE_HOW_IT_WORKS} className="btn btn-info">How It Works</Link>
                                    <br /><br />
                                    {
                                        tutorRequest.id == null
                                            ?   <ThemeSkeleton />
                                            :   <VerticalTimeline key={"vtime-" + tutorRequest.id} statuses={timelineStatuses}/>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col sm={10} md={10} lg={8} xl={6}>
                                    <Card>
                                        <CardBody className="pr-5 pl-5">
                                            <h4>{filteredProposals.length} {filteredProposals.length == 1 ? "Tutor" : "Tutors"}</h4>
                                            <p>Click on each tutor to view details.</p>
                                            {loading && tutorRequest.id == null 
                                                ?
                                                    <ThemeSkeleton />
                                                :
                                                    <Row>
                                                        <Col sm="4" xs="12">
                                                            <DropdownButton
                                                                className="border-0 f-16 p-0 min-w-150 cut-texts"
                                                                variant="outline-default"
                                                                onSelect={this.filterProposals}
                                                                title={"Filter: " + selectedTitle}
                                                                >
                                                                {
                                                                    filterOptions.map((option, i) =>
                                                                        <Dropdown.Item
                                                                            key={"dropdown-"+i}
                                                                            eventKey={option.id}
                                                                            active={selectedFilterOption === option.id}
                                                                        >
                                                                            <span>{option.title}</span>
                                                                        </Dropdown.Item>
                                                                    )
                                                                }
                                                            </DropdownButton>

                                                            <DropdownButton
                                                                className="border-0 f-16 p-0 min-w-150 cut-texts"
                                                                variant="outline-default"
                                                                onSelect={(eventKey) => this.setState({selectedSortOption: eventKey})}
                                                                title={"Sort: " + selectedSortTitle}
                                                                >
                                                                {
                                                                    sortOptions.map((sortOpt, i) =>
                                                                        <Dropdown.Item
                                                                            key={"dropdown-sort-"+i}
                                                                            eventKey={sortOpt.id}
                                                                            active={selectedSortOption === sortOpt.id}
                                                                        >
                                                                            <span>{sortOpt.title}</span>
                                                                        </Dropdown.Item>
                                                                    )
                                                                }
                                                            </DropdownButton>
                                                            <br />
                                                        </Col>
                                                        <Col sm="3" xs="12">
                                                            <div className="custom-control custom-checkbox mb-3">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="inPerson"
                                                                    name="inPerson"
                                                                    value={filterInPerson}
                                                                    checked={filterInPerson}
                                                                    onChange={() => this.setState({filterInPerson: !filterInPerson})}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="inPerson"
                                                                >
                                                                    In-Person Tutors
                                                                </label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox mb-3">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id="online"
                                                                    name="online"
                                                                    value={filterOnline}
                                                                    checked={filterOnline == true}
                                                                    onChange={() => this.setState({filterOnline: !filterOnline})}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor="online"
                                                                >
                                                                    Online Tutors
                                                                </label>
                                                            </div>
                                                            <br />
                                                        </Col>
                                                        <Col sm="4" xs={{size: 8, offset: 1}}>
                                                                <br /><br />
                                                                <RateSlider onChange={this.onChangeRate} minRate={10} maxRate={200} />
                                                                <center><label className="mt-2">Hourly Rate</label></center>
                                                        </Col>
                                                    </Row>
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    filteredProposals.map((proposal, i) =>
                                        <Col  key={i} sm="4" md="3">
                                            <MarketProposalSummaryCard proposal={proposal} onShowDetails={this.onShowDetails} />
                                        </Col>
                                    )
                                }
                            </Row>                            
                            {
                                selectedProposal.id &&
                                <Modal
                                    isOpen={showDetails}
                                    role="dialog"
                                    autoFocus={true}
                                    centered={true}
                                    className="proposalModal"
                                    tabIndex="-1"
                                    size="lg"
                                    toggle={() => this.setState({showDetails: !showDetails})}
                                >
                                    <div className="modal-content">
                                        <ModalHeader toggle={() => this.setState({showDetails: !showDetails})}>
                                            {selectedProposal.tutorName}'s Proposal
                                        </ModalHeader>
                                        <ModalBody>
                                            <MarketProposalDetailsCard proposal={selectedProposal} onSubmitDecision={this.submitProposalDecision} submitting={loading} />
                                            {loading && <center><ThemeSpinner /></center>}
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button
                                                type="button"
                                                color="secondary"
                                                onClick={()=> {this.setState({showDetails: false})}}
                                            >
                                                Close
                                            </Button>
                                            </ModalFooter>
                                    </div>
                                </Modal>
                            }
                        </Col>
                    </Row>
                </>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MarketTutorRequestProgress)
