
export function createTimelineStatuses(tutorRequest){
    let timelineStatuses = [];
    let initialStatus = {id: 1,
                    active: true,
                    title: "Tutor Request Submitted",
                    description: "Your tutor request has been received and is being processed",
                    details: "We are searching our network for suitable tutors based on your stated criteria.  Please allow 1-2 days (though we typically process requests in less than 12 hours).",
                    completed: false                
                };
    let reviewTutorStatus = { id: 2,
                active: true,
                title: "Review Tutors",
                description: "Review compiled list of potential tutors below.",
                details: "We've selected tutors who best fit your request. Review each tutor's details (profile, references, rate), and vote Yes/No on each potential tutor.  Tutors that you express interest in working with will be contacted and, if available, you will receive proposals from them.",
                completed: false
            };

    let selectTutorStatus = { id: 3,
                active: false,
                title: "Select A Tutor",
                description: "Review proposals below from available tutors and select the tutor(s) you want to work with.",
                details: "Available tutors will submit proposals, including the rate and tutoring type (online vs in-person). Review each proposal and select the tutor(s) you want to work with (feel free to work with more than one tutor). You will be required to pay upfront for the first session with each selected tutor.",
                completed: false
            };
    
    let finalStatus = { id: 10,
                active: false,
                title: "Get To Work!",
                description: "Contact your tutor and schedule your first session. Good luck! ",
                details: "Once you've paid for the first session, your tutor's contact information will be listed on your Dashboard.  Email, call, text your tutor at your convenience.  Your contact information will also be shared with your selected tutor at this point."
            };

    if(tutorRequest != null && tutorRequest != {}){
        timelineStatuses.push(initialStatus);

        //Step 1: Review Potential Tutors
        let potentialTutors = tutorRequest.potentialTutors || [];
        let tutorContracts = tutorRequest.tutorContracts || [];

        if(potentialTutors.length > 0){
            initialStatus["active"] = false;
            initialStatus["completed"] = true;

            let reviewed = false;
            potentialTutors.forEach(potentialTutor => {
                reviewed = potentialTutor.interested != null ? true : false;
            });

            if(reviewed){
                reviewTutorStatus["active"] = false;
                reviewTutorStatus["completed"] = true;
                selectTutorStatus["active"] = true;
            }

            timelineStatuses.push(reviewTutorStatus);    
        }
        
        //Step 2: Select Tutor
        if(tutorContracts.length > 0){
            initialStatus["completed"] = true;
            initialStatus["active"] = false;
            reviewTutorStatus["active"] = false;
            reviewTutorStatus["completed"] = true;
            selectTutorStatus["active"] = true;

            let selected = false;
            tutorContracts.forEach(tutorContract => {
                selected = tutorContract.studentDecision == true ? true : false;
            });

            if(selected){
                selectTutorStatus["active"] = false;
                selectTutorStatus["completed"] = true;
                finalStatus["active"] = true;
            }
        }
        
        timelineStatuses.push(selectTutorStatus);
        timelineStatuses.push(finalStatus);
    }

    return timelineStatuses;
}