import React, { Component } from "react"
import { connect } from "react-redux"
import { Alert, Card, CardBody, CardTitle } from "reactstrap"
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

import { triggerReducerLoadingAction, submitApiCallWithReducerAction } from "store/actions"

import { capitalizeWords } from "scripts/general";
import { ThemeSpinner } from "theme/elements/spinner";
import { ThemeButton } from "theme/elements/buttons";
import { LOAD_SUBJECTS, SAVE_SUBJECTS, SUBJECTS_ERROR } from "store/subjects/actionTypes";

class SubjectsDualList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.selected || [],
            optionGroup: []
        }
    }

    componentDidMount() {
        let { subjectsReducer } = this.props;
        
        if(subjectsReducer.subjects.length == 0){
            this.loadSubjects();
        }
    }

    loadSubjects = () =>{
        let { onReducerLoading, onSubmitApiCallWithReducer } = this.props;
        onReducerLoading(LOAD_SUBJECTS);
        onSubmitApiCallWithReducer("getSubjects", SAVE_SUBJECTS, null, SUBJECTS_ERROR);
    }

    onChange = (selected) => {
        this.setState({ selected });
    };

    formatSubjectsData = subjectsFromServer => {
        let optionGroup = [];

        subjectsFromServer.forEach(subject => {
            let label = subject.parentName != null ? capitalizeWords(subject.parentName.replace(/_/g, " ")) : "Unclassified";
            let parentId = subject.parentId != undefined && subject.parentId != null ? subject.parentId  : 99999999999;
            let option = { value: subject.id, label: capitalizeWords(subject.name.replace(/_/g, " ")) };
            let options = [];
                
            if(optionGroup.length > 0){
                let groupIndex = optionGroup.findIndex(og => og.parentId == parentId);
                if(groupIndex == -1){
                    //Create new group
                    options.push(option);

                    optionGroup.push({
                        label,
                        options,

                        //Custom
                        parentId
                    })
                }
                else{
                    optionGroup[groupIndex].options.push(option);
                }
            }
            else{
                options.push(option);

                optionGroup.push({
                    label,
                    options,

                    //Custom
                    parentId
                })
            }
        });

        return optionGroup;
    }

    saveSubjects = () => {
        if(this.props.onSubmit != undefined){
            const { selected } = this.state;
            this.props.onSubmit(selected);
        }
    }

    render() {
        const { selected } = this.state;
        const { title="", subjectsReducer } = this.props;
        const loading = subjectsReducer.loading;
        const error = subjectsReducer.error;

        let optionGroup = this.formatSubjectsData(subjectsReducer.subjects);

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle className="h4">{title}</CardTitle>
                        {
                            loading &&
                                <center><ThemeSpinner /></center>
                        }
                        {
                            error && 
                                <Alert color="danger">{error}</Alert>
                        }
                        <a className="text-primary" onClick={this.loadSubjects} >Reload Subjects</a>
                        <br /><br />
                        {
                            optionGroup.length > 0 && 
                                <DualListBox
                                    canFilter
                                    filterCallback={(Optgroup, filterInput) => {
                                        if (filterInput === '') {
                                            return true;
                                        }

                                        return (new RegExp(filterInput, 'i')).test(Optgroup.label);
                                    }}
                                    filterPlaceholder="Search..."
                                    options={optionGroup}
                                    selected={selected}
                                    onChange={this.onChange}
                                />
                        }
                        <br />
                        {
                            loading ?
                                <ThemeButton disabled={true}>Processing...</ThemeButton>
                                :
                                    <ThemeButton onClick={this.saveSubjects}>Submit</ThemeButton>
                        }
                       
                        
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ subjectsReducer }) => ({
    subjectsReducer,
  })
  
  const mapDispatchToProps = dispatch => ({
    onReducerLoading: reducerAction => dispatch(triggerReducerLoadingAction(reducerAction)),
    onSubmitApiCallWithReducer: (method, reducerAction, data, errorAction) => dispatch(submitApiCallWithReducerAction(method, reducerAction, data, errorAction)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(SubjectsDualList)