import React, { Component } from 'react'
import { connect } from 'react-redux'

import Select from "react-select"
import { Alert, Col, Row } from 'reactstrap';
import { submitApiCallAction } from "store/actions"

import { ThemeButton } from 'theme/elements/buttons';
import { ThemeInput, ThemeRadio, ThemeSelect } from "theme/elements/styledInputs"

import {sitemap_courses, sitemap_high_school_courses} from "constants/subjects"
import {sitemap_universities as university_details} from 'constants/universities';
import { cities, ca_cities } from 'constants/cities';
import { states, statesInverted } from 'constants/states_codes';

class GoogleSubmitURLForm extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             action: "submit",
             locations: "",
             universities: "",
             jobTitles: "",
             urlPrefix: "/jobs/tutor-jobs",
             urlType: "tutor-jobs",
             urls: "",
             urlCount: 0,
             subjects: sitemap_courses.join("\n"),
             subjectsHighSchool: sitemap_high_school_courses.join("\n"),
             submitted: false,
             showJobs: true,
             showUni: false,
             countryCode: "US",
             countryCodesArray: []
        }
        this.onChangeValue.bind(this)
        this.onSubmit.bind(this)
    }

    componentDidMount() { 
        this.loadLocations();
        let countryCodesArray = []
        
        Object.keys(university_details).forEach(countryCode => {
            countryCodesArray.push({label: countryCode, value: countryCode });
        })
        if(countryCodesArray.length > 0){
            this.setState({countryCodesArray});
        }
        this.loadCountryUniversities();

        let jobTitlesArray = ["university tutor"];
        sitemap_courses.forEach(course => {
            jobTitlesArray.push(course + " tutor");
        })
        let jobTitles = jobTitlesArray.join("\n");
        this.setState({jobTitles});
    }

    onChangeValue = (event) => {
        const { name, value } = event.target;
        this.setState({
              ...this.state,
              [name]: value
        });
    }

    onSubmit = event => {
        event.preventDefault();

        let { action, urls } = this.state;
        let { onSubmitApiCall } = this.props;

        onSubmitApiCall("postSubmitURLsToGoogle", { action, urls });
        this.setState({submitted: true});
    }

    loadCountryUniversities = () => {
        let { countryCode } = this.state;
        let uniArray = [];
        university_details[countryCode].forEach(detail => {
            let uni = detail.university.replace(/&/g, "and")
                                .replace(/\./g, "")
                                .replace(/ - /g, "-")
                                .replace(/\-/g, " ");
            uniArray.push(uni);
        })

        this.setState({universities: uniArray.join("\n")});
    }

    loadLocations = () => {
        let locations = "";
        let locationsArray = [];
        Object.keys(cities).forEach(state =>{
            let stateAbbr = statesInverted[state];
            if(stateAbbr != undefined){
                let stateCities = cities[state]; //array
                
                stateCities.forEach(city =>{
                    city = city.replace(/\./g, "").replace(/\-/g, " ");
                    locationsArray.push(city + ", " + stateAbbr);
                });
            }
            
        });

        locations = locationsArray.join("\n");
        this.setState({locations});
    }

    loadCanadaLocations = () => {
        let locations = "";
        let locationsArray = [];
        Object.keys(ca_cities).forEach(state =>{
                let stateCities = ca_cities[state]; //array
                stateCities.forEach(city =>{
                    city = city.replace(/\./g, "").replace(/\-/g, " ");
                    locationsArray.push(city + ", " + state);
                });
        });

        locations = locationsArray.join("\n");
        this.setState({locations});
    }
    
    createURLs = () => {
        let { urlType } = this.state;
        let urlPrefix = "";

        switch (urlType) {
            case "tutor-jobs":
                urlPrefix = "/jobs/tutor-jobs";
                this.setState({urlPrefix}, () => {this.createTutorJobsURLs();})
                break;

            case "flyer-jobs":
                urlPrefix = "/jobs/marketing/flyer-distribution";
                this.setState({urlPrefix}, () => {this.createFlyerJobsURLs();})
                break;

            case "location-jobs":
                urlPrefix = "/jobs";
                this.setState({urlPrefix}, () => {this.createLocationJobsURLs();})
                break;

            case "subject":
                    urlPrefix = "/g"; // + /course_name-tutors
                    this.setState({urlPrefix}, () => {this.createSubjectURLs();})
                    break;

            case "subject-location":
                urlPrefix = "/tutors"; // + /city-state-course_name-tutors
                this.setState({urlPrefix}, () => {this.createSubjectLocationURLs();})
                break;

            case "subject-university":
                urlPrefix = "/c"; // + /uni_name-course_name-tutors
                this.setState({urlPrefix}, () => {this.createSubjectUniURLs();})
                break;

            case "location":
                urlPrefix = "/tutors"; // + /city-state-tutors
                this.setState({urlPrefix}, () => {this.createLocationURLs();})
                break;
            
            case "state":
                urlPrefix = "/s"; // + /state/city-course-tutors
                this.setState({urlPrefix}, () => {this.createStateURLs();})
                break;

            case "stateAbbr":
                urlPrefix = "/a"; // + /state_abbr/city-course-tutors
                this.setState({urlPrefix}, () => {this.createStateAbbrURLs();})
                break;

            case "canada":
                    urlPrefix = "/ca"; // + /state/city-course-tutors
                    this.setState({urlPrefix}, () => {this.createCanadaURLs();})
                    break;

            default:
                break;
        }

    }

    createTutorJobsURLs = () => {
        let { urlPrefix, locations, jobTitles  } = this.state;
        
        let urls = "";
        let urlCount = 0;

        let baseURL = "https://bufftutor.com";
        baseURL += urlPrefix;

        let locationsArray = locations.trim().split("\n").filter(loc => loc);
        let jobsArray = jobTitles.trim().split("\n").filter(title => title);

        for (let i = 0; i < locationsArray.length; i++) {
            const loc = locationsArray[i];

            let locArray = loc.split(",");
            let city = locArray[0] != undefined ? locArray[0].trim().replace(/ /g, "_").toLowerCase() : "";
            let state = locArray[1] != undefined ? locArray[1].trim().replace(/ /g, "_").toLowerCase() : "";

            for (let j = 0; j < jobsArray.length; j++) {
                let jobTitle = jobsArray[j].trim().replace(/ /g, "_").toLowerCase();
                let jobId = (Math.random() * 1E9).toFixed(0);
                urls += baseURL + "/" + jobTitle + "-" + city + "-" + state + "-" + jobId;
                urls += "\n";
                urlCount++;
            }
        }

        urls.trim();

        this.setState({ urls, urlCount });
    }

    createFlyerJobsURLs = () =>{
        let { urlPrefix, locations  } = this.state;
        
        let urls = "";
        let urlCount = 0;

        let baseURL = "https://bufftutor.com";
        baseURL += urlPrefix;

        let locationsArray = locations.trim().split("\n").filter(loc => loc);

        for (let i = 0; i < locationsArray.length; i++) {
            const loc = locationsArray[i];

            let locArray = loc.split(",");
            let city = locArray[0] != undefined ? locArray[0].trim().replace(/ /g, "_").toLowerCase() : "";
            let state = locArray[1] != undefined ? locArray[1].trim().replace(/ /g, "_").toLowerCase() : "";

            urls += baseURL + "/" + city + "-" + state;
            urls += "\n";
            urlCount++;
        }

        urls.trim();

        this.setState({ urls, urlCount });
    }

    createLocationJobsURLs = () =>{
        let { urlPrefix, locations  } = this.state;
        
        let urls = "";
        let urlCount = 0;

        let baseURL = "https://bufftutor.com";
        baseURL += urlPrefix;

        let locationsArray = locations.trim().split("\n").filter(loc => loc);

        for (let i = 0; i < locationsArray.length; i++) {
            const loc = locationsArray[i];

            let locArray = loc.split(",");
            let city = locArray[0] != undefined ? locArray[0].trim().replace(/ /g, "_").toLowerCase() : "";
            let state = locArray[1] != undefined ? locArray[1].trim().replace(/ /g, "_").toLowerCase() : "";

            urls += baseURL + "/" + city + "-" + state;
            urls += "\n";
            urlCount++;
        }

        urls.trim();

        this.setState({ urls, urlCount });
    }

    createSubjectURLs = () => {
        // URLS: /g/course_name-tutors
        let { urlPrefix, subjects, subjectsHighSchool } = this.state;
        
        let urls = [];
        let urlCount = 0;

        let baseURL = "https://bufftutor.com";
        baseURL += urlPrefix;

        let subjectsArray = subjects.trim().split("\n").filter(subject => subject);
        let subjectsHighSchoolArray = subjectsHighSchool.trim().split("\n").filter(subject => subject);
        subjectsArray = [...subjectsArray, ...subjectsHighSchoolArray];

        for (let j = 0; j < subjectsArray.length; j++) {
            let subject_name = subjectsArray[j].trim().replace(/-/g, " ").replace(/ /g, "_").toLowerCase();
            urls += baseURL + "/" + subject_name + "-tutors";
            urls += "\n";
            urlCount++;
        }

        urls.trim();

        this.setState({ urls, urlCount });
    }

    createSubjectLocationURLs = () => {
        // URLS: /tutors/city-state-course_name-tutors
        let { urlPrefix, locations, subjects, subjectsHighSchool } = this.state;
        
        let urls = [];
        let urlCount = 0;

        let baseURL = "https://bufftutor.com";
        baseURL += urlPrefix;

        let locationsArray = locations.trim().split("\n").filter(loc => loc);
        let subjectsArray = subjects.trim().split("\n").filter(subject => subject);
        let subjectsHighSchoolArray = subjectsHighSchool.trim().split("\n").filter(subject => subject);
        subjectsArray = [...subjectsArray, ...subjectsHighSchoolArray];

        for (let i = 0; i < locationsArray.length; i++) {
            const loc = locationsArray[i];

            let locArray = loc.split(",");
            let city = locArray[0] != undefined ? locArray[0].trim().replace(/ /g, "_").toLowerCase() : "";
            let state = locArray[1] != undefined ? locArray[1].trim().replace(/ /g, "_").toLowerCase() : "";

            for (let j = 0; j < subjectsArray.length; j++) {
                let subject_name = subjectsArray[j].trim().replace(/-/g, " ").replace(/ /g, "_").toLowerCase();
                
                if(j == 0){
                    urls += baseURL + "/" + city + "-" + state + "-tutors\n";
                }

                urls += baseURL + "/" + city + "-" + state + "-" + subject_name + "-tutors";
                urls += "\n";
                urlCount++;
            }
        }

        urls.trim();

        this.setState({ urls, urlCount });
    }

    createSubjectUniURLs = () => {
        // URL: /c/uni_name-course_name-tutors
        let { urlPrefix, locations, universities, subjects  } = this.state;
        
        let urls = [];
        let urlCount = 0;

        let baseURL = "https://bufftutor.com";
        baseURL += urlPrefix;

        let uniArray = universities.trim().split("\n").filter(uni => uni);
        let subjectsArray = subjects.trim().split("\n").filter(subject => subject);
        
        for (let i = 0; i < uniArray.length; i++) {
            const uni = uniArray[i].trim()
                                    .replace(/ - /g, "-")
                                    .replace(/-/g, " ")
                                    .replace(/\(/g, " ")
                                    .replace(/\)/g, " ")
                                    .replace(/\./g, "")
                                    .replace(/ /g, "_")
                                    .toLowerCase();

            for (let j = 0; j < subjectsArray.length; j++) {
                let subject_name = subjectsArray[j].trim().replace(/-/g, " ").replace(/ /g, "_").toLowerCase();
                
                if(j == 0){
                    urls += baseURL + "/" + uni + "-tutors\n";
                }

                urls += baseURL + "/" + uni + "-" + subject_name + "-tutors";
                urls += "\n";
                urlCount++;
            }
        }

        urls.trim();

        this.setState({ urls, urlCount });
    }

    createLocationURLs = () => {
        // URLS: /tutors/city-state-tutors
        let { urlPrefix, locations  } = this.state;
        
        let urls = [];
        let urlCount = 0;

        let baseURL = "https://bufftutor.com";
        baseURL += urlPrefix;

        let locationsArray = locations.trim().split("\n").filter(loc => loc);

        for (let i = 0; i < locationsArray.length; i++) {
            const loc = locationsArray[i];

            let locArray = loc.split(",");
            let city = locArray[0] != undefined ? locArray[0].trim().replace(/ /g, "_").toLowerCase() : "";
            let state = locArray[1] != undefined ? locArray[1].trim().replace(/ /g, "_").toLowerCase() : "";

            urls += baseURL + "/" + city + "-" + state + "-tutors\n";
            urlCount++;
        }

        urls.trim();

        this.setState({ urls, urlCount });
    }

    createStateURLs = () => {
        // URLS: /s/state/city-course-tutors
        let { urlPrefix, locations, subjects, subjectsHighSchool } = this.state;
        
        let urls = [];
        let urlCount = 0;

        let baseURL = "https://bufftutor.com";
        baseURL += urlPrefix;

        let locationsArray = locations.trim().split("\n").filter(loc => loc);
        let subjectsArray = subjects.trim().split("\n").filter(subject => subject);
        let subjectsHighSchoolArray = subjectsHighSchool.trim().split("\n").filter(subject => subject);
        subjectsArray = [...subjectsArray, ...subjectsHighSchoolArray];

        for (let i = 0; i < locationsArray.length; i++) {
            const loc = locationsArray[i];

            let locArray = loc.split(",");
            let city = locArray[0] != undefined ? locArray[0].trim().replace(/ /g, "_").toLowerCase() : "";
            let state = locArray[1] != undefined ? states[locArray[1].trim()] : undefined;
            
            if(state != undefined){
                state = state.trim().replace(/ /g, "_").toLowerCase();
                urlCount++;

                for (let j = 0; j < subjectsArray.length; j++) {
                    let subject_name = subjectsArray[j].trim().replace(/-/g, " ").replace(/ /g, "_").toLowerCase();
                    
                    let url = "";
                    if(j == 0){
                        url = baseURL + "/" + state + "/" + city + "-tutors";
                        urls.push(url);
                    }
    
                    url = baseURL + "/" + state + "/" + city + "-" + subject_name + "-tutors";
                    urls.push(url);
                    urlCount++;
                }
            }
        }

        urls = urls.join("\n");

        this.setState({ urls, urlCount });
    }

    createStateAbbrURLs = () => {
        // URLS: /a/state_abbr/city-course-tutors
        let { urlPrefix, locations, subjects, subjectsHighSchool  } = this.state;
        
        let urls = [];
        let urlCount = 0;

        let baseURL = "https://bufftutor.com";
        baseURL += urlPrefix;

        let locationsArray = locations.trim().split("\n").filter(loc => loc);
        let subjectsArray = subjects.trim().split("\n").filter(subject => subject);
        let subjectsHighSchoolArray = subjectsHighSchool.trim().split("\n").filter(subject => subject);
        subjectsArray = [...subjectsArray, ...subjectsHighSchoolArray];
        
        for (let i = 0; i < locationsArray.length; i++) {
            const loc = locationsArray[i];

            let locArray = loc.split(",");
            let city = locArray[0] != undefined ? locArray[0].trim().replace(/ /g, "_").toLowerCase() : "";
            let state = locArray[1];
            
            if(state != undefined){
                state = state.trim().toLowerCase();
                urlCount++;

                for (let j = 0; j < subjectsArray.length; j++) {
                    let subject_name = subjectsArray[j].trim().replace(/-/g, " ").replace(/ /g, "_").toLowerCase();
                    
                    let url = "";
                    if(j == 0){
                        url = baseURL + "/" + state + "/" + city + "-tutors";
                        urls.push(url);
                    }
    
                    url = baseURL + "/" + state + "/" + city + "-" + subject_name + "-tutors";
                    urls.push(url);
                    urlCount++;
                }
            }
        }

        urls = urls.join("\n");

        this.setState({ urls, urlCount });
    }

    createCanadaURLs = () => {
        // URLS: /ca/state/city-course-tutors
        let { urlPrefix, locations, subjects } = this.state;
        
        let urls = [];
        let urlCount = 0;

        let baseURL = "https://bufftutor.com";
        baseURL += urlPrefix;

        let locationsArray = locations.trim().split("\n").filter(loc => loc);
        let subjectsArray = subjects.trim().split("\n").filter(subject => subject);

        for (let i = 0; i < locationsArray.length; i++) {
            const loc = locationsArray[i];

            let locArray = loc.split(",");
            let city = locArray[0] != undefined ? locArray[0].trim().replace(/ /g, "_").replace(/\./g, "").toLowerCase() : "";
            let state = locArray[1];
            
            if(state != undefined){
                state = state.trim().replace(/ /g, "_").replace(/\./g, "").toLowerCase();
                urlCount++;

                for (let j = 0; j < subjectsArray.length; j++) {
                    let subject_name = subjectsArray[j].trim().replace(/-/g, " ").replace(/ /g, "_").toLowerCase();
                    
                    let url = "";
                    if(j == 0){
                        url = baseURL + "/" + state + "/" + city + "-tutors";
                        urls.push(url);
                    }
    
                    url = baseURL + "/" + state + "/" + city + "-" + subject_name + "-tutors";
                    urls.push(url);
                    urlCount++;
                }
            }
        }

        urls = urls.join("\n");

        this.setState({ urls, urlCount });
    }

    render() {
        let { action, locations, universities, jobTitles, urlPrefix, urlType, urls, urlCount, subjects, showJobs, showUni } = this.state;
        let { apiResponse } = this.props;

        return (
            <>
                {
                    apiResponse.error && 
                        <Alert color="danger">{apiResponse.error}</Alert>
                }
                <Row>
                    <Col md={{size: 2, offset:2}}>
                        <ThemeRadio
                            id="action-submit"
                            name="action"
                            value="submit"
                            label="Submit Urls"
                            checked={action == "submit" ? true : false}
                            onChange={this.onChangeValue}
                        />
                    </Col>
                    <Col md={{size: 2}}>
                        <ThemeRadio
                            id="action-delete"
                            name="action"
                            value="delete"
                            label="Delete Urls"
                            checked={action == "delete" ? true : false}
                            onChange={this.onChangeValue}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={{size: 3, offset:2}}>
                        <ThemeRadio
                            id="urlType-tutor-job"
                            name="urlType"
                            value="tutor-jobs"
                            label="Tutor Jobs: /jobs/tutor-jobs"
                            checked={urlType == "tutor-jobs" ? true : false}
                            onChange={event => {this.onChangeValue(event); this.setState({showJobs: true, showUni: false}, () => this.loadLocations());}}
                        />
                    </Col>
                    <Col md={{size: 3}}>
                        <ThemeRadio
                            id="urlType-location-jobs"
                            name="urlType"
                            value="location-jobs"
                            label="Location Jobs Page: /jobs/{city-state}"
                            checked={urlType == "location-jobs" ? true : false}
                            onChange={event => {this.onChangeValue(event); this.setState({showJobs: true, showUni: false}, () => this.loadLocations());}}
                        />
                    </Col>
                    <Col md={{size: 3}}>
                        <ThemeRadio
                            id="urlType-flyer"
                            name="urlType"
                            value="flyer-jobs"
                            label="Flyer Distribution: /jobs/marketing/flyer-distribution/{city-state}"
                            checked={urlType == "flyer-jobs" ? true : false}
                            onChange={event => {this.onChangeValue(event); this.setState({showJobs: true, showUni: false}, () => this.loadLocations());}}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={{size: 3, offset:2}}>
                        <ThemeRadio
                            id="urlType-subject"
                            name="urlType"
                            value="subject"
                            label="Subject Pages: /g/course_name"
                            checked={urlType == "subject" ? true : false}
                            onChange={event => {this.onChangeValue(event); this.setState({showJobs: false, showUni: false}, () => this.loadLocations());}}
                        />
                    </Col>
                    <Col md={{size: 3}}>
                        <ThemeRadio
                            id="urlType-subject-location"
                            name="urlType"
                            value="subject-location"
                            label="Subject - Location Pages: /tutors/city-state-course_name-tutors"
                            checked={urlType == "subject-location" ? true : false}
                            onChange={event => {this.onChangeValue(event); this.setState({showJobs: false, showUni: false}, () => this.loadLocations());}}
                        />
                    </Col>
                    <Col md={{size: 3}}>
                        <ThemeRadio
                            id="urlType-subject-university"
                            name="urlType"
                            value="subject-university"
                            label="Subject Uni Pages: /c/uni_name-course_name-tutors"
                            checked={urlType == "subject-university" ? true : false}
                            onChange={event => {this.onChangeValue(event); this.setState({showJobs: false, showUni: true});}}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={{size: 3, offset:2}}>
                        <ThemeRadio
                            id="urlType-location"
                            name="urlType"
                            value="location"
                            label="Location Pages: /tutors/city-state-tutors"
                            checked={urlType == "location" ? true : false}
                            onChange={event => {this.onChangeValue(event); this.setState({showJobs: false, showUni: false}, () => this.loadLocations());}}
                        />
                    </Col>
                    <Col md={{size: 3}}>
                        <ThemeRadio
                            id="urlType-state"
                            name="urlType"
                            value="state"
                            label="Location Pages: /s/state/city-course-tutors"
                            checked={urlType == "state" ? true : false}
                            onChange={event => {this.onChangeValue(event); this.setState({showJobs: false, showUni: false}, () => this.loadLocations());}}
                        />
                    </Col>
                </Row>
                <br /><br />
                <Row>
                    <Col md={{size: 3, offset: 2}}>
                        <ThemeRadio
                            id="urlType-stateAbbr"
                            name="urlType"
                            value="stateAbbr"
                            label="Location State Abbr Pages: /a/state_abbr/city-course-tutors"
                            checked={urlType == "stateAbbr" ? true : false}
                            onChange={event => {this.onChangeValue(event); this.setState({showJobs: false, showUni: false}, () => this.loadLocations());}}
                        />
                    </Col>
                    <Col md={{size: 3}}>
                        <ThemeRadio
                            id="urlType-canada"
                            name="urlType"
                            value="canada"
                            label="Canada Pages: /ca/state/city-course-tutors"
                            checked={urlType == "canada" ? true : false}
                            onChange={event => {this.onChangeValue(event); this.setState({showJobs: false, showUni: false}, () => this.loadCanadaLocations());}}
                        />
                    </Col>
                    <Col md={{size: 3}}>
                        <ThemeRadio
                            id="urlType-canadaJobs"
                            name="urlType"
                            value="canadaJobs"
                            label="Canada Jobs Pages (TODO!!!): /jobs/ca/......"
                            checked={urlType == "canadaJobs" ? true : false}
                            onChange={event => {this.onChangeValue(event); this.setState({showJobs: true, showUni: false}, () => this.loadCanadaLocations());}}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={{size: 2, offset: 2}}>
                        <ThemeInput 
                            id="urlPrefix"
                            name="urlPrefix"
                            value={urlPrefix}
                            placeholder="URL Prefix"
                            onChange={this.onChangeValue}
                            required={true}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    {
                        showJobs 
                            ?
                                <Col md={{size: 4, offset:2}}>
                                    <textarea id="jobTitles" cols={30} rows={5} name="jobTitles" value={jobTitles} placeholder="Job Titles" onChange={event => {this.onChangeValue(event); this.createURLs()}} >

                                    </textarea>
                                </Col>
                            :
                                <Col md={{size: 4, offset:2}}>
                                    <textarea id="subjects" cols={30} rows={5} name="subjects"  value={subjects} placeholder="Subjects" onChange={event => {this.onChangeValue(event); this.createURLs()}}>
            
                                    </textarea>
                                </Col>
                    }
                    {
                        showUni ?
                            <Col md={{size: 4, offset:1}}>
                                <textarea id="universities" cols={30} rows={5} name="universities" value={universities} placeholder={showJobs ? "Universities" : "Universities"} onChange={event => {this.onChangeValue(event); this.createURLs()}}>

                                </textarea>
                                <Row>
                                    <Col xs="4">Selected Country: {this.state.countryCode}</Col>
                                    <Col sm="6">
                                        <Select options={this.state.countryCodesArray}
                                            isMulti={false}  
                                            hideSelectedOptions={true}
                                            defaultValue={{label: "US", value: "US"}}
                                            placeholder={"Country Codes"}
                                            onChange={codeDetails => {codeDetails != null && typeof codeDetails.value !== "undefined" ? this.setState({countryCode: codeDetails.value}, () => this.loadCountryUniversities()) : this.setState({universities: ""})}} />
                                    </Col>
                                </Row>
                            </Col>
                        :
                            <Col md={{size: 4, offset:1}}>
                                <textarea id="locations" cols={30} rows={5} name="locations" value={locations} placeholder={showJobs ? "Locations - City, State (2 Letter)" : "Locations - City, State (2 Letter) OR University Name"} onChange={event => {this.onChangeValue(event); this.createURLs()}}>

                                </textarea>
                            </Col>
                    }
                </Row>
                <br />
                
                <hr />
                <br />
                <Row>
                    <Col md={{size: 4, offset:2}}>
                        <textarea id="urls" cols={50} rows={5} name="urls" value={urls} placeholder="URLs" onChange={this.onChangeValue}>

                        </textarea>
                    </Col>
                </Row>
                <Row>
                    <Col md={{size: 4, offset:8}}>
                        <div className="mt-3">
                            {
                                apiResponse.loading ?
                                    <ThemeButton color={action == "delete" ? "danger" : "primary"}  disabled={true} active={false} icon={"bx bx-loader bx-spin"}> {action == "delete" ? "Deleting" : "Submitting"} </ThemeButton>
                                :
                                    <ThemeButton color={action == "delete" ? "danger" : "primary"} onClick={this.onSubmit} disabled={urls == null || urls == "" ? true : false}> {action == "delete" ? "Delete" : "Submit"} URLs</ThemeButton>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={{size: 8, offset:2}}>
                        URL Count: {urlCount}<br />
                        Max Per Batch = 100, Max Per Day = 20,000 <br />
                        <a href="https://console.cloud.google.com/apis/api/indexing.googleapis.com/metrics?project=indexing-api-2022" target="_blank" rel="noreferrer">Indexing API + Quotas</a>
                        <br />
                        <hr />
                        <pre>{urls}</pre>
                        <br />
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer
})

const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(GoogleSubmitURLForm)