import React, { Component } from "react"
import moment from "moment"
import { Link } from "react-router-dom"
import { Button, Card, Col, Row, UncontrolledTooltip } from "reactstrap"

import ThemeAvatar from "theme/images/ThemeAvatar";

import { formatDateTime, getCurrencySymbol } from 'scripts/general'

import { TUTOR_PROFILE, CHAT_INBOX } from "routes/paths"

import { replaceAllLinks } from "scripts/chat";
import BTNStarRatings from "components/ratings/BTNStarRatings";

import { deadlineHasPassed } from "./proposalFilterOptions";
import TutorRating from "components/ratings/TutorStarRating";
import TutorStarRating from "components/ratings/TutorStarRating";

export default class MarketProposalDetailsCard extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
          studentDecision: this.props.proposal.studentDecision,
          rejectReason: ""
        }
        this.onSubmitStudentDecision.bind(this);
    }

    onSubmitStudentDecision = async(decision) => {
        let { proposal } = this.props;
        let { rejectReason } = this.state;
    
        let formData = { decision, rejectReason, proposalId: proposal.id };
        
        if(typeof this.props.onSubmitDecision !== undefined){
          this.props.onSubmitDecision(formData);
        }
    }

    render() {
        let { proposal, submitting } = this.props;
        let { studentDecision } = this.state;
        let currencySymbol = getCurrencySymbol(proposal.currency || "usd");
        let rate = proposal.rate;
        
        let chatURL = CHAT_INBOX;
        if(proposal.threadId != null && proposal.threadId != ""){
            chatURL +="?threadId=" + proposal.threadId;
        }

        let expired = deadlineHasPassed(proposal);
        
        return (
            <Card style={expired == true ? {backgroundColor: "#f0f0f0"} : {backgroundColor: "white"}}>
                <Row>
                    <Col md="4">
                        <div className="text-center p-2 border-end">
                            <div className="avatar-xl mx-auto mb-2 mt-1">
                                <ThemeAvatar avatar={proposal.tutorAvatar} name={proposal.tutorName} size="xl" rounded="rounded" />
                            </div>
                            <br />
                            <center>
                                <h4 className="text-dark mb-1">
                                    {proposal.tutorName}
                                </h4>
                                <h5>
                                    {proposal.city && <span className="text-muted">{proposal.city}, {proposal.state}</span>}
                                </h5>
                                
                                <TutorStarRating rating={proposal.tutorRating} reviewsCount={proposal.reviewsCount} />
                                <br /><br />
                                
                                <h5 className="text-danger mb-1 mb-lg-3">
                                    {currencySymbol}{rate}/hr
                                </h5>
                                {
                                    proposal.inPerson == true &&
                                    <span className="badge badge-primary" style={{fontSize: "14px", margin: "5px", padding: "6px"}}>In Person Tutoring</span>
                                }
                                {" "}
                                {
                                    proposal.online == true &&
                                    <span className="badge badge-success" style={{fontSize: "14px", margin: "5px", padding: "6px"}}>Online Tutoring</span>
                                }
                            </center>
                        </div>
                    </Col>
                    <Col md="8">
                        <div className="p-4 text-xl-start">
                            <Row>
                                <Col xs="12">
                                    <p className="font-size-15 text-muted" style={{fontWeight: "bold"}}>
                                        {proposal.education}<br />
                                        {proposal.major}
                                    </p>
                                    <p className="font-size-15">
                                        {proposal.experience} {" "}
                                        <Link to={TUTOR_PROFILE + "/" + proposal.tutorId} className="text-primary" target="_blank">
                                            View Profile
                                        </Link>
                                    </p>
                                </Col>
                            </Row>
                            {
                                proposal.message &&
                                    <Row>
                                        <Col xs={{size:"10", offset: 1}} style={{border: "solid 2px lightgray", borderRadius: "5px", padding: "2rem"}}>
                                            <center>
                                            <p className="text-dark" style={{fontSize: "16px", fontWeight: ""}}>"{replaceAllLinks(proposal.message) + "..."}" <br /></p>
                                            <Link to={chatURL} className="btn btn-outline-dark btn-md me-2 w-md"><i className="bx bx-chat" />{" "}Chat</Link></center>
                                        </Col>
                                    </Row>
                            }
                            {
                                proposal.studentDeadline &&
                                    <Row className="mt-4 text-center">
                                        <Col>
                                        <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                            <h5 className="font-size-14" id="duedateTooltip">
                                            <i className="bx bx-calendar mr-1 text-primary" />
                                            Respond By:{" "}
                                            {
                                                moment().diff(proposal.studentDeadline, 'minutes') > 0 
                                                ? <span className={"text-danger"}>{moment(proposal.studentDeadline).fromNow()}</span>
                                                : <span className={"text-dark"}>{formatDateTime(proposal.studentDeadline)}</span>

                                            }
                                            
                                            <UncontrolledTooltip
                                                placement="top"
                                                target="duedateTooltip"
                                            >
                                                Response Deadline
                                            </UncontrolledTooltip>
                                            </h5>
                                        </li>
                                        </ul>
                                        </Col>
                                    </Row>
                            }
                        
                            
                            <Row className="mt-4 text-center">
                                <Col xs="6">
                                    {
                                        expired || submitting
                                        ?
                                            <Button color="info"
                                                className="btn-rounded btn-md w-md"
                                                disabled={true}>
                                                Accept Proposal
                                            </Button>
                                        :
                                        <Button color="info"
                                                className="btn-rounded btn-md w-md"
                                                onClick={() => this.onSubmitStudentDecision(true)}>
                                            Accept Proposal
                                        </Button>
                                    }
                                </Col>
                                <Col xs="6">
                                    {
                                        expired || submitting
                                        ?
                                            <Button color="danger"
                                                className="btn-rounded btn-md w-md"
                                                disabled={true}>
                                                {studentDecision !== false ? "Decline Proposal" : "Declined"}
                                            </Button>
                                        :
                                            <Button color="danger"
                                                className="btn-rounded btn-md w-md"
                                                onClick={() => this.onSubmitStudentDecision(false)}
                                                disabled={studentDecision !== false  ? false : true}>
                                                    {studentDecision !== false ? "Decline Proposal" : "Declined"}
                                            </Button>
                                    }
                                </Col>
                                {
                                    expired &&
                                        <p className="mt-3 text-secondary"><b>Want to work with {proposal.tutorName}?</b> Please <a href="mailto:Contact@BuffTutor.com">email us</a> to extend the deadline to respond. </p>
                                }
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Card>
        )
  }
}

