import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import { Redirect } from 'react-router-dom'

import BTNMetaTags from "components/metatags/BTNMetaTags"

import { submitApiCallAction } from "store/actions"
import { processApiResponse } from 'scripts/api'
import { formatDate } from "scripts/general"
import { ADMIN_USER_DETAILS, ADMIN_TUTOR_APPLICATIONS, PROFICIENCY_EXAM_LOAD_ASSESSMENT_SOLUTIONS } from 'routes/paths'

import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import { ThemeSpinner } from 'theme/elements/spinner'
import { ThemeSoftBadge } from 'theme/elements/badges'
import { ThemeButton } from 'theme/elements/buttons'
import { ThemeInput, ThemeRadio } from 'theme/elements/styledInputs'
import SubjectsDualList from 'components/dualList/SubjectsDualList'

class TutorApplicationDetails extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            applicationId: props.match.params.applicationId || "",
            application: {},
            examList: "",
            offered: null,
            reason: "",
            redirect: false,
            showFreeBackground: true,
        }
    }
    
    componentDidMount() {
        let { onSubmitApiCall } = this.props;
        let { applicationId } = this.state;

        onSubmitApiCall("getAdminTutorApplication", applicationId);
    }

    componentDidUpdate(prevProps, prevState) {
        let { apiResponse = {} } = this.props;
        
        if(prevProps != this.props){
            let newState = processApiResponse(apiResponse, this.state);

            if(apiResponse != {} && prevState != newState){
                this.setState(newState);
            }
        }
    }

    sendExamEmailReminder = () => {
        if(confirm("Are you sure you want to send the exam reminder?")){
            let { onSubmitApiCall } = this.props;
            let { application, examList } = this.state;

            let formData = {tutorApplicantId: application.id, examList};
            onSubmitApiCall("sendTutorApplicantEmailReminder", formData);
            this.setState({redirect: true});
        }
    }

    createFreeBackgroundCheck = () => {
        this.setState({showFreeBackground: false});
        
        let { onSubmitApiCall } = this.props;
        let { application } = this.state;

        onSubmitApiCall("createFreeBackgroundCheckForApplicant", application.id);
    }

    submitPositionOffer = () => {
        let { onSubmitApiCall } = this.props;
        let { application, offered } = this.state;

        let formData = {tutorApplicantId: application.id, offered};
        onSubmitApiCall("tutorApplicationOfferPosition", formData);
        this.setState({redirect: true});
    }

    deleteApplication = () => {
        if(confirm("Are you sure you want to delete this application?")){
            let { onSubmitApiCall } = this.props;
            let { application } = this.state;            
            onSubmitApiCall("deleteTutorApplication", application.id);
        }
        this.setState({redirect: true});
    }

    clearAndHireTutor = (payment = true) =>{
        let { onSubmitApiCall } = this.props;
        let { application } = this.state;
        const userId = application.userId;
        const backgroundCheckId = application.backgroundCheckId;

        
        if(!payment){
            if(!confirm("Clear Tutor Without Background Check Payment?")){
                return;
            }
            onSubmitApiCall("adminClearBackgroundWithoutPayment", userId);
        }
        else{
            let formData = {backgroundCheckId, cleared: true, status: "complete", result: "clear"}
            onSubmitApiCall("adminUpdateBackgroundCheck", formData);
        }

        const hireFormData = {tutorApplicantId: application.id, hired: true};
        onSubmitApiCall("hireTutorApplicant", hireFormData);

        this.setState({redirect: true});
    }

    onDoNotHire = () => {
        let { onSubmitApiCall } = this.props;
        let { application, reason } = this.state;
        const hireFormData = {tutorApplicantId: application.id, hired: false, reason};
        onSubmitApiCall("hireTutorApplicant", hireFormData);

        this.setState({redirect: true});
    }

    convertApplicantToAdAccount = applicantRefId => {
        if(confirm("Are you sure you want to CONVERT this application?")){
            let { onSubmitApiCall } = this.props;         
            onSubmitApiCall("convertApplicantToAdAccount", applicantRefId);
        }
    }

    updateSubjects = subjectIds => {
        let { application } = this.state;
        let subjects = [];

        subjectIds.forEach(subjectId => {
            subjects.push({id: subjectId})
        })

        let formData = {subjects, tutorId: application.userRefId};
        
        let { onSubmitApiCall } = this.props;         
        onSubmitApiCall("updateProfileSubjects", formData);
    }

    render() {
        const { application, offered, reason, redirect, examList, showFreeBackground } = this.state;
        const { apiResponse } = this.props;
        let loading = apiResponse.loading
        
        let subjectIds = [];
        let subjectNames = [];

        if(application.subjects){
            subjectIds = application.subjects.map(subject => subject.id);
            subjectNames = application.subjects.map(subject => camelCaseToWords(subject.name));
        }

        let title = application.name ? `${application.name}'s Tutor Application` : "Tutor Application";

        return (
            <>
                <BTNMetaTags title={title} />
                 
                {
                    redirect &&
                        <Redirect to={ADMIN_TUTOR_APPLICATIONS} />
                }
                {
                    loading &&
                        <center><ThemeSpinner /></center>
                }
                <Row>
                    <Col md="6">
                        <Card>
                            <CardBody>
                                <CardTitle>{application.name}'s Application 
                                    {
                                        application.sponsorship && 
                                            <ThemeSoftBadge color="danger">Requires Sponsorship</ThemeSoftBadge>
                                    }
                                </CardTitle>
                                <Row>
                                    <Col sm="7">
                                        ID: {application.id} / {application.refId}<br />
                                        Status: {application.status} <br /><br />
                                        <Link to={ADMIN_USER_DETAILS + "/" + application.userRefId}>{application.name}</Link><br />
                                        <a href={`https://www.google.com/maps/place/${application.city},+${application.state}`} target="_blank" rel="noreferrer">{application.city}, {application.state}</a><br />
                                        <a onClick={() => {navigator.clipboard.writeText(application.email)}}>{application.email}</a><br />
                                        {application.phone}<br />
                                        {
                                            application.offered === true &&
                                                <ThemeSoftBadge color="info">Offered</ThemeSoftBadge>
                                        }
                                        {" "}
                                        {
                                            application.offered === false &&
                                                <ThemeSoftBadge color="warning">Not Offered</ThemeSoftBadge>
                                        }
                                        {" "}
                                        {
                                            application.hired === true &&
                                                <ThemeSoftBadge color="success">Hired</ThemeSoftBadge>
                                        }
                                        {" "}
                                        {
                                            application.hired === false &&
                                                <ThemeSoftBadge color="danger">Not Hired</ThemeSoftBadge>
                                        }
                                        <br />
                                        <br />
                                        {
                                            application.offered !== true && 
                                                <ThemeButton className="btn btn-sm pull-right" color="warning" onClick={() => this.convertApplicantToAdAccount(application.refId)}>Convert To Leads Account</ThemeButton>
                                        }
                                    </Col>
                                    <Col sm="5">
                                        <ThemeButton color="danger" onClick={this.deleteApplication}>Delete Application</ThemeButton>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card>
                            <CardBody>
                                <CardTitle>Update Application</CardTitle>
                                {
                                    application.backgroundPaid !== true && showFreeBackground == true &&
                                        <>
                                            <br />
                                            <ThemeButton color="info" onClick={this.createFreeBackgroundCheck}>Create Free Background Check</ThemeButton>    
                                            <br />
                                        </>
                                }
                                {
                                    application.offered !== true &&
                                        <>
                                            <CardTitle>Preliminary Offer</CardTitle>
                                            <Row>
                                                <Col sm ="8">
                                                    <ThemeRadio id="offerTrue" name="offered" value={true} checked={offered === true} label="Offer Position" onChange={() => this.setState({ offered : true})}/>
                                                    { application.offered !== false && 
                                                        <ThemeRadio id="offerFalse" name="offered" value={false} checked={offered === false} label="Do NOT Offer Position" onChange={() => this.setState({ offered : false})} />
                                                    }
                                                </Col>
                                                <Col sm ="4">
                                                    <ThemeButton color="primary" onClick={this.submitPositionOffer}>Submit</ThemeButton>
                                                </Col>
                                            </Row>
                                        </>
                                }
                                <br />
                                
                                {
                                    application.offered == true &&
                                        <>
                                            <Row>
                                                <Col sm="6">
                                                    <CardTitle>Background Checks</CardTitle>
                                                    <b>ID: </b> {application.backgroundCheckId}<br />
                                                    <b>Status: </b> {application.backgroundStatus}<br />
                                                    <b>Result: </b> {application.backgroundResult}<br />
                                                    <b>Paid: </b> {application.backgroundPaid ? "Paid" : "Not Paid"}<br />
                                                    <b>Cleared: </b> {application.backgroundCleared ? "Passed" : "Not Passed"}<br />
                                                    <br />
                                                </Col>
                                                { application.backgroundCleared !== true &&
                                                    <Col sm="6">
                                                        {
                                                            application.backgroundPaid 
                                                                ?
                                                                    <ThemeButton color="primary" onClick={this.clearAndHireTutor}>Clear & Hire Tutor</ThemeButton>
                                                                :
                                                                    <ThemeButton color="secondary" onClick={() => this.clearAndHireTutor(false)}>Clear & Hire Tutor <br />WITHOUT PAYMENT</ThemeButton>
                                                        }
                                                    </Col>
                                                }
                                            </Row>
                                            
                                           {
                                               application.hired !== true &&
                                                <Row>
                                                    <Col sm ="6">
                                                        <ThemeInput name="reason" value={reason} placeholder="Reason for not hiring" onChange={event => this.setState({reason: event.target.value})} /><br />
                                                        <ThemeButton color="danger" onClick={this.onDoNotHire}>Do Not Hire</ThemeButton>
                                                    </Col>
                                                </Row>
                                           }
                                        </>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <Card>
                            <CardBody>
                                <CardTitle>Applicant Details</CardTitle>
                                <b>Created At: </b>{application.createdAt ? moment(application.createdAt).fromNow() : ""}<br />
                                <b>Submitted At: </b>{application.submittedAt ? moment(application.submittedAt).fromNow() : ""}<br />
                                <b>Dates: </b> {formatDate(application.startDate)} - {formatDate(application.endDate)} <br />
                                <b>Major: </b> {application.major}<br />
                                <b>School: </b> {application.school}<br />
                                {
                                    application.professionalLink && 
                                        <>
                                            <b>Professional Link: </b> <a href={`${application.professionalLink}`} target="_blank" rel="noreferrer">{application.professionalLink}</a><br />
                                        </>
                                }
                                <b>Availability: </b> {application.availability}<br />
                                
                                <b>Marketing: </b>{application.marketing}<br />
                                
                                {
                                    application.inPerson === true &&
                                        <ThemeSoftBadge color="info">In Person</ThemeSoftBadge>
                                }
                                {" "}
                                {
                                    application.online === true &&
                                        <ThemeSoftBadge color="success">Online</ThemeSoftBadge>
                                }
                                <br />

                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card>
                            <CardBody>
                                <CardTitle>Courses</CardTitle>
                                <p style={{ whiteSpace: "pre-wrap"}}>{application.courses}</p>
                                
                                <Row>
                                    <Col md="5">
                                        <CardTitle>Transcripts</CardTitle>
                                        {
                                            application.uploads && application.uploads.length == 0 &&
                                                <p><em>No Uploads</em></p>
                                        }
                                        {
                                            application.uploads && application.uploads.map((upload, k) =>
                                                <span key={k}>
                                                    {
                                                        
                                                        Object.getOwnPropertyNames(upload).map((fileName, idx) =>
                                                            <a key={idx} href={upload[fileName]} target="_blank" rel="noreferrer">{fileName.split("/").pop()}</a>
                                                        )
                                                    }
                                                    <br />
                                                </span>
                                            )
                                        }
                                    </Col>
                                    <Col md="7">
                                        <CardTitle>Proficiency Exams</CardTitle>
                                        {
                                            application.assessmentResults && application.assessmentResults.map((result, j) =>
                                                <Link to={PROFICIENCY_EXAM_LOAD_ASSESSMENT_SOLUTIONS + "/" + result.id} key={"link-"+j} target="_blank" rel="noreferrer">
                                                    <div>{result.proficiencyExamName || result.name} - {result.grade != null && <span className={result.grade < 70 ? "text-danger" : (result.grade >= 80 ? "text-success" : "text-dark")}>{result.grade}%</span>} </div><br />
                                                </Link>
                                            )
                                        }
                                        <Row>
                                            <Col sm ="12">
                                                <ThemeInput name="examList" value={examList} placeholder="Exams for applicant to pass" onChange={event => this.setState({examList: event.target.value})} /><br />
                                                <ThemeButton color="info" onClick={this.sendExamEmailReminder}>Send Reminder</ThemeButton>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm ="12">
                                        <SubjectsDualList onSubmit={this.updateSubjects} selected={subjectIds}/>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    
                </Row>
                <Row>
                    <Col lg="8">
                        <Card>
                            <CardBody>
                                <CardTitle>Experience</CardTitle>
                                <div style={{ whiteSpace: "pre-wrap"}}>{application.experience}</div>
                                
                                {
                                    application.concerns && 
                                        <>
                                            <br /><br />
                                            <CardTitle>Concerns</CardTitle>
                                            {application.concerns}
                                        </>
                                }

                                {
                                    application.adminNotes && 
                                        <>
                                            <br /><br />
                                            <CardTitle>Admin Notes</CardTitle>
                                            <div style={{ whiteSpace: "pre-wrap"}}>{application.adminNotes}</div>
                                        </>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = ({ apiResponseReducer }) => ({
    apiResponse: apiResponseReducer,
  })
  
const mapDispatchToProps = dispatch => ({
    onSubmitApiCall: (method, data) => dispatch(submitApiCallAction(method, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TutorApplicationDetails)
