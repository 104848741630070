
import React, { Component } from 'react'
import { connect } from "react-redux"
import { Alert, Spinner, UncontrolledAlert } from "reactstrap";

import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer, LinkAuthenticationElement, PaymentElement } from '@stripe/react-stripe-js';

import { createPaymentIntent, createSetupIntent } from 'scripts/stripe';
import { formatCurrencyAmount } from 'scripts/general';

let loadingStripe = true;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
  betas: ['link_beta_3'],
  apiVersion: "2020-08-27;link_beta=v1"
});

stripePromise.then(() => {loadingStripe = false});

class CheckoutForm extends React.Component {
  
    constructor(props) {
        super(props)
        this.state = {
          submitting: false,
          error: "",
        }
        
        this.handleSubmit.bind(this);
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        const {stripe, elements, chargeCard } = this.props;
        this.setState({submitting: true});

        let errorMessage = null;
        let results = null;
        let response = {};

        if(chargeCard){
            response = await stripe.confirmPayment({
                elements,
                // confirmParams: {
                //   return_url: "https://example.com/order/123/complete",
                // },

                // Uncomment below if you only want redirect for redirect-based payments
                redirect: "if_required",
            });
        }
        else{
            response = await stripe.confirmSetup({
                elements,
                // confirmParams: {
                //   return_url: "https://example.com/order/123/complete",
                // },

                // Uncomment below if you only want redirect for redirect-based payments
                redirect: "if_required",
            });
        }
    
        if (response.error) {
            errorMessage = response.error.message;
        }
        else {
          results = true;
        }
    
        this.setState({ submitting: false, error: errorMessage});
    
        if (this.props.onSubmit != null && typeof this.props.onSubmit == "function") {
          this.props.onSubmit({error: errorMessage, results});
        }
    }

    render() {
      const { email, stripe, amount, currency, saveCard, chargeCard } = this.props;
      let { submitting, error } = this.state;
      let total = formatCurrencyAmount(amount, currency);
      
      return (
        <>
          <form onSubmit={this.handleSubmit}>
            <div className="CheckoutForm">
              
              <PaymentElement />    
              <br />
              {/* <LinkAuthenticationElement
                // Optional prop for prefilling customer information
                options={{
                  defaultValues: {
                    email,
                  },
                }}
              /> */}
              <br />

              { submitting ?
                  <button
                      type="button"
                      className="btn btn-info waves-effect waves-light"
                      disabled={true}
                  >
                      Submitting {" "}{" "}{" "}
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                  </button>
                :
                  chargeCard ?
                    <button
                        type="submit"
                        className="btn btn-info waves-effect waves-light"
                        disabled={!stripe || (amount == undefined || amount <= 0)}>
                        Submit Payment: {total}
                    </button>
                    :
                    <button
                        type="submit"
                        className="btn btn-info waves-effect waves-light"
                        disabled={!stripe}>
                        Save Payment Method
                    </button>
              }
            </div>
          </form>
          <br />
          {
              error && 
              <Alert color="danger">{error}</Alert>
          } 
        </>
      );
    }
}

function InjectedCheckoutForm({email, amount, currency, onSubmit, metadata, saveCard, chargeCard}) {
    return (
      <ElementsConsumer>
        {({stripe, elements }) => (
          <CheckoutForm  stripe={stripe} elements={elements}
                        email={email}
                        amount={amount}
                        currency={currency}
                        onSubmit={onSubmit}
                        metadata={metadata}
                        saveCard={saveCard}
                        chargeCard={chargeCard}
                        />
        )}
      </ElementsConsumer>
    );
  }

class PaymentForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
          error: "",
          clientSecret: "",
        }
        this.createStripeIntent.bind(this);
    }

    async componentDidMount() { 
        this.setState({error: ""});
    
        let { clientSecret, error } = await this.createStripeIntent();
          
        this.setState({error, clientSecret});
    }

    createStripeIntent = async () => {
        let { saveCard, chargeCard, metadata, method = "card" } = this.props;
        
        metadata = {...metadata, method, savePaymentMethod: saveCard };
        let clientSecret = chargeCard ? await createPaymentIntent(metadata) : await createSetupIntent(metadata);
        
        return clientSecret;
    }

    render() {
        let  { user, amount, currency, onSubmit=null, metadata={}, saveCard=false, chargeCard=false } = this.props;
        let { clientSecret, error } = this.state;

        const options = { clientSecret };
        
        return (
            <>  
                { error && <UncontrolledAlert color="danger">{error}</UncontrolledAlert> }
                { loadingStripe && <center><Spinner color="warning" /></center> }
                {
                    clientSecret != null && clientSecret != "" &&
                    <Elements stripe={stripePromise} options={options}>
                        <InjectedCheckoutForm
                            email={user.email}
                            amount={amount}
                            currency={currency}
                            onSubmit={onSubmit}
                            metadata={metadata}
                            saveCard={saveCard}
                            chargeCard={chargeCard}
                            />
                    </Elements>
                }
            </>
        )
      }
}

const mapStateToProps = ({ userReducer }) => ({
  user: userReducer
})

export default connect(mapStateToProps, null)(PaymentForm)